import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useContext } from "react";
import { HiChevronDown } from "react-icons/hi2";
import { FcViewDetails } from "react-icons/fc";
import Carousel from "react-multi-carousel";
import { responsive } from "../tracks/Track";
import { IOngoingSpecDetails, updateSpecializationFavourites } from "../../data/specializationDetails";
import { UserContext } from "../../App";
import InProgressSpecCard from "./InProgressSpecializationCard";
export default function InProgressSpecializations(props: { specs: IOngoingSpecDetails[]; setSpecs: (specs: IOngoingSpecDetails[]) => void }) {
	const user = useContext(UserContext).userDetails;
	const updateFavourites = (specializationId: number) => {
		updateSpecializationFavourites(user.LdpUserID, specializationId).then(() => {
			let updatedSpecializations = props.specs.map((item) => {
				if (item.SpecializationID === specializationId) {
					return { ...item, IsFavourite: !item.IsFavourite };
				}
				return item;
			});
			props.setSpecs(updatedSpecializations);
		});
	};
	return (
		<Accordion sx={{ boxSizing: "border-box", width: "100%" }} defaultExpanded={true}>
			<AccordionSummary expandIcon={<HiChevronDown size={30} />}>
				<FcViewDetails size={30} />
				<Typography variant={"h5"} ml={{ sm: 2, xs: 1 }}>
					Ongoing Specializations
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Carousel responsive={responsive}>
					{props.specs.map((item, index) => (
						<InProgressSpecCard specializationDetails={item} updateFavourite={updateFavourites} key={"ip_sp-" + index} />
					))}
				</Carousel>
			</AccordionDetails>
		</Accordion>
	);
}
