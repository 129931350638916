import { axiosInstance } from "./axiosInstance";

export interface IUserDetails {
	Email: string;
	DisplayName: string;
	Designation: string;
	LdpUserID: number;
	ProfileURL: string;
	MsLink: string[];
}

export const defaultUserDetails: IUserDetails = {
	Email: "",
	DisplayName: "",
	Designation: "",
	LdpUserID: 0,
	ProfileURL: "",
	MsLink: [],
};

export const fetchUserDetails = async (email: string) => {
	let userDetails: IUserDetails = { ...defaultUserDetails };
	let msLinks: string[] = [];
	await axiosInstance
		.post("/details/GetUserDetails", email, {
			headers: { "Content-Type": "application/json" },
		})
		.then((userResponse) => {
			if (userResponse.data.Data) {
				let details = userResponse.data.Data[0];
				userDetails.Email = details.Email;
				userDetails.DisplayName = details.Name;
				userDetails.Designation = details.Designation;
				userDetails.LdpUserID = details.LdpUserID;
				//There can be multiple MS Learn links for the user
				userResponse.data.Data.forEach((details: any) => {
					if (details.MSLearnUsername) {
						msLinks.push(details.MSLearnUsername);
					}
				});
				userDetails.MsLink = msLinks;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	await axiosInstance
		.get("https://graph.microsoft.com/beta/me/photo/$value", {
			responseType: "blob",
		})
		.then((profileResponse) => {
			const url = window.URL || window.webkitURL;
			const blobUrl = url.createObjectURL(profileResponse.data);
			userDetails.ProfileURL = blobUrl;
		})
		.catch((error) => {
			console.log(error);
			userDetails.ProfileURL = "";
		});
	return userDetails;
};

export const addMsLearnLink = async (details: { LdpUserID: number; msLink: string }) => {
	await axiosInstance.post("/details/AddMSLearnLink", details).catch((error) => {
		console.log(error);
	});
};
export const deleteMsLearnLink = async (details: { LdpUserID: number; msLink: string }) => {
	await axiosInstance.post("/details/DeleteMSLearnLink", details).catch((error) => {
		console.log(error);
	});
};
