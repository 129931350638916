import { Box, Card, Link, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { INewsDetails } from "../../data/homeDetails";
import parse from "html-react-parser";
export default function NewsFeedCard(props: { newsDetails: INewsDetails }) {
	const theme = useTheme();
	return (
		<Card
			elevation={3}
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "calc(100% - 16px)",
				width: "400px",
				maxWidth: "90%",
				margin: "8px auto",
				"&:hover": { opacity: 0.4 },
			}}
		>
			<Link href={props.newsDetails.Url} color={"inherit"} target={"_blank"} underline={"none"}>
				<Box m={2} sx={{ overflow: "hidden" }} height={"7em"}>
					<Typography
						variant={"h5"}
						sx={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							WebkitLineClamp: 1,
							WebkitBoxOrient: "vertical",
							mb: 2,
						}}
						title={props.newsDetails.Subject}
					>
						{props.newsDetails.Subject}
					</Typography>
					<Typography
						sx={{
							fontSize: "12px",
							fontWeight: "normal",
							"& *": { fontSize: "12px", fontWeight: "normal" },
							"& a": { color: theme.palette.mode === "dark" ? "#8ab4f8" : "#1a0dab" },
						}}
					>
						{props.newsDetails.Content && parse(props.newsDetails.Content.replaceAll(/<img(.*?)>|style="(.*?)"/g, ""))}
					</Typography>
				</Box>
				<Box display={"flex"} justifyContent={"space-between"} m={2}>
					<Typography variant={"subtitle2"}>
						<b>{props.newsDetails.Sender}</b>
					</Typography>
					<Typography variant={"caption"}>{moment(props.newsDetails.CreatedDate).format("MMMM DD, YYYY")}</Typography>
				</Box>
			</Link>
		</Card>
	);
}
