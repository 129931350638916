import { axiosInstance } from "./axiosInstance";
export interface IBadgeDetails {
  TrackID: number;
  Track: string;
  BadgeReceived: boolean;
}
export interface IRecommendationDetails {
    TrackID: number;
    SpecializationID: number;
    Track: string;
    SpecializationName: string;
}

export const fetchBadgeDetails = async (userId: number) => {
  let badgeDetails: IBadgeDetails[] = [];
  await axiosInstance
    .post("/details/getBadgeDetails", userId, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      badgeDetails = response.data.Data;
    })
    .catch((error) => {
      console.log(error);
    });
  return badgeDetails;
};
export const fetchRecommendationDetails = async (userId: number) => {
    let RecommendationDetails: IRecommendationDetails[] = [];
  await axiosInstance
    .post("/details/GetUserRecommendation", userId, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      RecommendationDetails = response.data.Data;
    })
    .catch((error) => {
      console.log(error);
    });
    return RecommendationDetails;
};
