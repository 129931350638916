import { useContext, useEffect, useState } from "react";
import { Box, Stack } from "@mui/system";
import { Autocomplete, Checkbox, Skeleton, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Track from "./Track";
import { UserContext } from "../../App";
import { ITrackDetails, ITrackSpecDetails, fetchTrackSpecDetails } from "../../data/trackDetails";
import { specStatuses } from "../../data/specializationDetails";
import { MdGridView, MdOutlineViewCarousel } from "react-icons/md";

function getDistinctTracks(trackSpecDetails: ITrackSpecDetails[]) {
	let tracks: ITrackDetails[] = [];
	trackSpecDetails.forEach((trackSpec, index, self) => {
		if (index === self.findIndex((t) => t.TrackID === trackSpec.TrackID)) {
			tracks.push({ TrackID: trackSpec.TrackID, Track: trackSpec.Track });
		}
	});
	return tracks;
}

export default function Tracks(props: { onlyShowFavourites: boolean }) {
	const user = useContext(UserContext).userDetails;
	const [tracksLoaded, setTracksLoaded] = useState(false);
	const [trackSpecDetails, setTrackSpecDetails] = useState<ITrackSpecDetails[]>([]);
	const [trackDetails, setTrackDetails] = useState<ITrackDetails[]>([]);
	const [trackFilters, setTrackFilters] = useState<ITrackDetails[]>([]);
	const [specializationFilters, setSpecializationFilters] = useState<ITrackSpecDetails[]>([]);
	const [specStatusFilters, setSpecStatusFilters] = useState<string[]>([]);
	const preferredLayout = localStorage.getItem("preferredLayout");
	const [layout, setLayout] = useState<"grid" | "carousel">(preferredLayout === "grid" ? "grid" : "carousel");

	useEffect(() => {
		fetchTrackSpecDetails(user.LdpUserID)
			.then((resp: any[]) => setTrackSpecDetails(resp))
			.finally(() => setTracksLoaded(true));
	}, [user.LdpUserID]);

	useEffect(() => {
		localStorage.setItem("preferredLayout", layout);
	}, [layout]);

	useEffect(() => {
		setTrackDetails(getDistinctTracks(trackSpecDetails));
	}, [trackSpecDetails]);

	useEffect(() => {
		setTrackFilters(
			trackFilters.filter((track) =>
				getDistinctTracks(
					trackSpecDetails
						.filter((spec) => !props.onlyShowFavourites || spec.IsFavourite)
						.filter((spec) => specStatusFilters.length === 0 || specStatusFilters.includes(spec.SpecializationStatus))
				)
					.map((filteredTrack) => filteredTrack.TrackID)
					.includes(track.TrackID)
			)
		);
	}, [props.onlyShowFavourites, specStatusFilters]);

	useEffect(() => {
		setSpecializationFilters(
			specializationFilters
				.filter((spec) => !props.onlyShowFavourites || spec.IsFavourite)
				.filter((spec) => specStatusFilters.length === 0 || specStatusFilters.includes(spec.SpecializationStatus))
				.filter((spec) => trackFilters.length === 0 || trackFilters.map((track) => track.TrackID).includes(spec.TrackID))
		);
	}, [props.onlyShowFavourites, specStatusFilters, trackFilters]);

	const updateFavourite = (SpecializationID: number) => {
		let newTrackSpecDetails = [...trackSpecDetails];
		newTrackSpecDetails.forEach((specialization, index) => {
			if (specialization.SpecializationID === SpecializationID) {
				newTrackSpecDetails[index].IsFavourite = !newTrackSpecDetails[index].IsFavourite;
				setTrackSpecDetails([...newTrackSpecDetails]);
			}
		});
	};

	return (
		<Box p={{ sm: 2, xs: 1 }}>
			{tracksLoaded ? (
				<>
					<Box display={"flex"} mb={2} columnGap={2} rowGap={2} flexWrap={"wrap"}>
						<Autocomplete
							multiple={true}
							size={"small"}
							limitTags={1}
							disableCloseOnSelect
							renderInput={(params) => <TextField {...params} label={"Tracks"} />}
							options={getDistinctTracks(
								trackSpecDetails
									.filter((spec) => !props.onlyShowFavourites || spec.IsFavourite)
									.filter((spec) => specStatusFilters.length === 0 || specStatusFilters.includes(spec.SpecializationStatus))
							)}
							getOptionLabel={(option) => option.Track}
							renderOption={(props, option, { selected }) => (
								<li {...props}>
									<Checkbox style={{ marginRight: 8 }} checked={selected} />
									{option.Track}
								</li>
							)}
							value={trackFilters}
							isOptionEqualToValue={(option, value) => option.TrackID === value.TrackID}
							onChange={(event, newValue) => {
								setTrackFilters(newValue);
							}}
							sx={{ width: "100%", maxWidth: "240px", "& .MuiAutocomplete-tag": { maxWidth: "200px" } }}
						/>
						<Autocomplete
							multiple={true}
							size={"small"}
							limitTags={1}
							disableCloseOnSelect
							renderInput={(params) => <TextField {...params} label={"Specializations"} />}
							options={trackSpecDetails
								.filter((spec) => !props.onlyShowFavourites || spec.IsFavourite)
								.filter((spec) => specStatusFilters.length === 0 || specStatusFilters.includes(spec.SpecializationStatus))
								.filter((spec) => trackFilters.length === 0 || trackFilters.map((track) => track.TrackID).includes(spec.TrackID))}
							getOptionLabel={(option) => option.Specialization}
							renderOption={(props, option, { selected }) => (
								<li {...props}>
									<Checkbox style={{ marginRight: 8 }} checked={selected} />
									{option.Specialization}
								</li>
							)}
							groupBy={(option) => option.Track}
							value={specializationFilters}
							isOptionEqualToValue={(option, value) => option.SpecializationID === value.SpecializationID}
							onChange={(event, newValue) => {
								setSpecializationFilters(newValue);
							}}
							sx={{ mr: "auto", width: "100%", maxWidth: "480px", "& .MuiAutocomplete-tag": { maxWidth: "200px" } }}
						/>
						<ToggleButtonGroup
							value={specStatusFilters}
							onChange={(event, value: string[]) => setSpecStatusFilters(value)}
							aria-label={"Specialization Status Filters"}
							color={"info"}
							size={"small"}
						>
							{specStatuses.map((specStatus, index) => (
								<ToggleButton value={specStatus} aria-label={specStatus} key={"spec-filter-" + index}>
									{specStatus}
								</ToggleButton>
							))}
						</ToggleButtonGroup>
						<ToggleButtonGroup
							value={layout}
							onChange={(event, value: "grid" | "carousel" | null) => {
								if (value !== null) setLayout(value);
							}}
							aria-label={"Layout View"}
							color={"primary"}
							exclusive
						>
							<ToggleButton value={"grid"} aria-label={"grid"} title={"Grid View"} sx={{ height: "40px", width: "40px", p: 0 }}>
								<MdGridView size={20} />
							</ToggleButton>
							<ToggleButton value={"carousel"} aria-label={"carousel"} title={"Carousel View"} sx={{ height: "40px", width: "40px", p: 0 }}>
								<MdOutlineViewCarousel size={20} />
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
					<Stack spacing={2}>
						{trackDetails.map((track, index) => (
							<Track
								trackName={track.Track}
								specializations={trackSpecDetails
									.filter((spec) => spec.TrackID === track.TrackID && (!props.onlyShowFavourites || spec.IsFavourite))
									.filter((spec) => specStatusFilters.length === 0 || specStatusFilters.includes(spec.SpecializationStatus))
									.filter((spec) => trackFilters.length === 0 || trackFilters.map((track) => track.TrackID).includes(spec.TrackID))
									.filter(
										(spec) =>
											specializationFilters.length === 0 ||
											specializationFilters.map((specFilter) => specFilter.SpecializationID).includes(spec.SpecializationID)
									)}
								layout={layout}
								totalSpecializations={
									trackSpecDetails.filter((spec) => spec.TrackID === track.TrackID && (!props.onlyShowFavourites || spec.IsFavourite)).length
								}
								updateFavourite={updateFavourite}
								key={"track-" + index}
							/>
						))}
					</Stack>
				</>
			) : (
				<>
					<Box display={"flex"} mb={2} columnGap={2} rowGap={2} flexWrap={"wrap"}>
						<Skeleton variant={"rounded"} width={"100%"} height={"40px"} sx={{ maxWidth: "240px" }} />
						<Skeleton variant={"rounded"} width={"100%"} height={"40px"} sx={{ maxWidth: "480px", mr: "auto" }} />
						<Skeleton variant={"rounded"} width={"100%"} height={"40px"} sx={{ maxWidth: "320px" }} />
					</Box>
					<Stack spacing={2}>
						{[1, 2, 3, 4, 5, 6, 7].map(() => (
							<Skeleton variant={"rounded"} height={"100px"} />
						))}
					</Stack>
				</>
			)}
		</Box>
	);
}
