import { Link as RouterLink } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Link, List, ListItem, ListItemText, Paper, Typography, useTheme } from "@mui/material";
import { BarChart, XAxis, YAxis, Legend, Bar, Tooltip, ResponsiveContainer } from "recharts";
import { HiChevronDown } from "react-icons/hi2";
import { FcComboChart } from "react-icons/fc";
import { IEnrollmentAttemptMetrics, ITrackSpecCounts } from "../../data/homeDetails";

const CustomTooltip = ({ active, payload, label }: any) => {
	if (active && payload && payload.length) {
		return (
			<Paper elevation={12} sx={{ py: 1, pl: 2, pr: 5 }}>
				<Typography fontWeight={600} gutterBottom>
					{label}
				</Typography>
				{payload.map((payloadItem: any) => (
					<Typography fontSize={14}>{`${payloadItem.name}: ${payloadItem.value.toFixed(2)}%`}</Typography>
				))}
			</Paper>
		);
	}
	return null;
};

const CoursesCard = (props: { courses: IEnrollmentAttemptMetrics[]; title: string; keyText: string }) => (
	<Grid item xs={6}>
		<Paper elevation={3} sx={{ maxWidth: 360, minHeight: 150, px: 2, py: 1, m: "auto" }}>
			<Typography variant={"button"} display={"block"} noWrap>
				{props.title}
			</Typography>
			<List dense={true} disablePadding>
				{props.courses.map((course, index) => (
					<ListItem key={`${props.keyText}-${index}`}>
						<Link
							to={`tracks/specialization/${course.SpecializationID}/course/${course.CourseID}`}
							title={course.CourseName}
							component={RouterLink as any}
							noWrap
							fontWeight={600}
						>
							{course.CourseName}
						</Link>
						<ListItemText sx={{ textAlign: "end" }} primary={course.Count.toLocaleString("en-US")} />
					</ListItem>
				))}
			</List>
			{props.courses.length === 0 && (
				<Box width={"100%"} px={2} py={1.5}>
					<Typography fontSize={16} fontWeight={600} noWrap>
						No Data Available
					</Typography>
				</Box>
			)}
		</Paper>
	</Grid>
);

export default function Metrics(props: { trackSpecCounts: ITrackSpecCounts[]; enrollmentAttemptDetails: IEnrollmentAttemptMetrics[] }) {
	const theme = useTheme();
	const mostEnrolledCoursesinLastWeek = props.enrollmentAttemptDetails.filter((detail) => detail.Category === "Most Enrolled Courses in Last Week");
	const mostCompletedCoursesinLastWeek = props.enrollmentAttemptDetails.filter((detail) => detail.Category === "Most Completed Courses in Last Week");
	const mostInProgressCourses = props.enrollmentAttemptDetails.filter((detail) => detail.Category === "Most In Progress Courses");
	const questionsAttemptedinLastWeek = props.enrollmentAttemptDetails.filter((detail) => detail.Category === "Questions Attempted in Last Week")[0];

	return (
		<Accordion sx={{ boxSizing: "border-box", width: "100%" }} defaultExpanded={true}>
			<AccordionSummary expandIcon={<HiChevronDown size={30} />}>
				<FcComboChart size={30} />
				<Typography variant={"h5"} ml={{ sm: 2, xs: 1 }}>
					Metrics
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Box display={"flex"} flexDirection={{ xs: "column", md: "row" }} height={{ md: "340px" }} gap={2} width={"100%"}>
					<Box width={"100%"} minWidth={{ md: 600 }} maxWidth={{ md: 720 }} height={340} overflow={"auto"} sx={{ overflowY: "hidden" }}>
						<Typography variant={"button"} display={"block"} noWrap>
							Status of Specializations by Tracks
						</Typography>
						<ResponsiveContainer minWidth={600} width={"100%"} height={300}>
							<BarChart
								width={640}
								height={300}
								data={props.trackSpecCounts}
								margin={{
									top: 20,
									right: 30,
									left: 20,
									bottom: 5,
								}}
							>
								<XAxis
									dataKey="Track"
									opacity={0.8}
									stroke={theme.palette.text.primary}
									height={40}
									interval={0}
									tick={{ fill: theme.palette.text.primary, fontSize: "14px", width: 32 }}
									tickLine={{ stroke: theme.palette.text.primary }}
								/>
								<YAxis
									domain={[0, 100]}
									unit={"%"}
									opacity={0.8}
									stroke={theme.palette.text.primary}
									tick={{ fill: theme.palette.text.primary, fontSize: "14px" }}
									tickLine={{ stroke: theme.palette.text.primary }}
								/>
								<Legend />
								<Bar dataKey="Completed" stackId="a" fill="#82ca9d" barSize={32} legendType="circle" />
								<Bar dataKey="In Progress" stackId="a" fill="#8884d8" legendType="circle" />
								<Bar dataKey="Not Started" stackId="a" fill="#ffc658" legendType="circle" radius={[8, 8, 0, 0]} />
								<Tooltip content={<CustomTooltip />} cursor={false} />
							</BarChart>
						</ResponsiveContainer>
					</Box>
					<Grid container height={340} mt={0} overflow={"auto"} columns={{ xs: 12, md: 6, lg: 12 }} spacing={2}>
						<CoursesCard courses={mostEnrolledCoursesinLastWeek} title={"Most Enrolled Courses in Last Week"} keyText={"enrolled"} />
						<CoursesCard courses={mostCompletedCoursesinLastWeek} title={"Most Completed Courses in Last Week"} keyText={"completed"} />
						<CoursesCard courses={mostInProgressCourses} title={"Most In Progress Courses (All Time)"} keyText={"inprogress"} />
						<Grid item xs={6}>
							<Paper elevation={3} sx={{ maxWidth: 360, height: 150, px: 2, py: 1, m: "auto" }}>
								<Typography variant={"button"} display={"block"} noWrap>
									Questions Attempted in Last Week
								</Typography>
								<Box width={"100%"} px={2} py={1.5}>
									<Typography fontSize={16} fontWeight={600} noWrap>
										{questionsAttemptedinLastWeek.Count.toLocaleString("en-US")}
									</Typography>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</Box>
			</AccordionDetails>
		</Accordion>
	);
}
