import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/system";
import { Button, Divider, IconButton, List, Paper, Typography, useTheme } from "@mui/material";
import { IOngoingSpecDetails } from "../../data/specializationDetails";
import moment from "moment";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

export default function InProgressSpecCard(props: { specializationDetails: IOngoingSpecDetails; updateFavourite: (SpecializationID: number) => void }) {
	const theme = useTheme();
	return (
		<Paper
			elevation={3}
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "calc(100% - 16px)",
				width: "400px",
				maxWidth: "90%",
				margin: "8px auto",
				p: 1,
				boxSizing: "border-box",
			}}
		>
			<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} height={"3em"}>
				<Typography
					fontWeight={600}
					sx={{
						whiteSpace: "initial",
						overflow: "hidden",
						textOverflow: "ellipsis",
						display: "-webkit-box",
						WebkitLineClamp: 2,
						WebkitBoxOrient: "vertical",
					}}
				>
					{props.specializationDetails.Specialization}
				</Typography>
				<IconButton
					onClick={() => {
						props.updateFavourite(props.specializationDetails.SpecializationID);
					}}
					sx={{ height: "40px", width: "40px" }}
				>
					{props.specializationDetails.IsFavourite ? (
						<AiFillStar color={theme.palette.text.primary} />
					) : (
						<AiOutlineStar color={theme.palette.text.primary} />
					)}
				</IconButton>
			</Box>
			<Divider />
			<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} flexGrow={1} p={1}>
				<List disablePadding sx={{ whiteSpace: "nowrap" }}>
					<Typography variant={"subtitle2"}> {"Credits: " + props.specializationDetails.Credit}</Typography>
					<Typography variant={"subtitle2"}> {`Start Date: ${moment(props.specializationDetails.StartedOn).format("MMMM DD, YYYY")}`}</Typography>
					<Typography variant={"subtitle2"}>
						{" "}
						{`Last Updated: ${moment(props.specializationDetails.LastUpdatedOn).format("MMMM DD, YYYY")}`}
					</Typography>
					{props.specializationDetails.TotalModules > 0 && (
						<Typography variant={"subtitle2"}>
							{"Completed Modules: " + props.specializationDetails.CompletedModules + "/" + props.specializationDetails.TotalModules}
						</Typography>
					)}
					{props.specializationDetails.TotalQuizzes > 0 && (
						<Typography variant={"subtitle2"}>
							{"Completed Quizzes: " + props.specializationDetails.CompletedQuizzes + "/" + props.specializationDetails.TotalQuizzes}
						</Typography>
					)}
				</List>
				<Button component={RouterLink} to={`tracks/specialization/${props.specializationDetails.SpecializationID}`} sx={{ alignSelf: "flex-end" }}>
					View
				</Button>
			</Box>
		</Paper>
	);
}
