import {
  Box,
  Grid,
  Link,
  Paper,
  Skeleton,
  styled,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { UserContext } from "../../App";
import {
  fetchBadgeDetails,
  fetchRecommendationDetails,
  IBadgeDetails,
  IRecommendationDetails,
} from "../../data/profileDetails";
import BadgeIcon from "../../images/Badge.svg";
export default function Profile() {
  const user = useContext(UserContext).userDetails;
  const [badges, setBadges] = useState<IBadgeDetails[]>([]);
  const [recommendations, setRecommendations] = useState<
    IRecommendationDetails[]
  >([]);
  const [badgesLoaded, setBadgesLoaded] = useState(false);
  const [recommendationLoaded, setRecommendationLoaded] = useState(false);
  const [badgesEarned, setBadgesEarned] = useState(0);
  useEffect(() => {
    fetchBadgeDetails(user.LdpUserID)
      .then((badgeDetails) => {
        setBadges(badgeDetails);
        let badgeCount = badgeDetails.filter(
          (badge) => badge.BadgeReceived
        ).length;
        setBadgesEarned(badgeCount);
      })
      .finally(() => setBadgesLoaded(true));
    fetchRecommendationDetails(user.LdpUserID)
      .then((recommendationDetails) => {
        setRecommendations(recommendationDetails);
      })
      .finally(() => setRecommendationLoaded(true));
  }, [user]);
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  }));

  return (
    <Box p={2}>
      {badgesLoaded && recommendationLoaded ? (
        <>
          <Typography variant={"h5"} mb={2}>
            My Profile
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Item sx={{ height: "20vh" }}>
                    Summary (Feature Coming Soon)
                  </Item>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Item sx={{ height: "50vh" }}>
                    Progress (Feature Coming Soon)
                  </Item>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Item sx={{ height: "50vh", overflow: "auto" }}>
                    <h3 style={{ marginTop: "0px" }}>
                      Recommended Specializations
                    </h3>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          {recommendations.map((item, index) => (
                            <TableRow
                              key={index}
                              style={{ backgroundColor: "inherit" }}
                            >
                              <TableCell>
                                <Link
                                  to={`/tracks/specialization/${item.SpecializationID}`}
                                  title={item.SpecializationName}
                                  component={RouterLink as any}
                                  noWrap
                                  fontWeight={600}
                                >
                                  {item.SpecializationName}
                                </Link>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Item>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              <Item
                sx={{
                  height: "100%",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <Grid container alignItems={"center"}>
                  <Typography
                    variant={"h6"}
                  >{`Badges Earned (${badgesEarned})`}</Typography>
                  {badges.map((badge) => {
                    return (
                      <>
                        <Grid item sm={12} lg={6}>
                          <img
                            alt={badge.Track}
                            src={BadgeIcon}
                            height={80}
                            width={80}
                            style={{
                              opacity: badge.BadgeReceived ? "1" : "0.2",
                            }}
                          />
                        </Grid>
                        <Grid item sm={12} lg={6}>
                          <Typography variant={"subtitle1"}>
                            {badge.Track}
                          </Typography>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Item>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Typography variant={"h5"} mb={2}>
            <Skeleton width={"200px"} />
          </Typography>
          <Box
            display={"flex"}
            width={"100%"}
            height={"640px"}
            rowGap={2}
            columnGap={2}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"80%"}
              height={"100%"}
              rowGap={2}
              columnGap={2}
            >
              <Skeleton variant={"rounded"} width={"100%"} height={"30%"} />
              <Box
                display={"flex"}
                width={"100%"}
                height={"70%"}
                rowGap={2}
                columnGap={2}
              >
                <Skeleton variant={"rounded"} width={"50%"} height={"100%"} />
                <Skeleton variant={"rounded"} width={"50%"} height={"100%"} />
              </Box>
            </Box>
            <Skeleton variant={"rounded"} width={"20%"} height={"100%"} />
          </Box>
        </>
      )}
    </Box>
  );
}
