import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import SpecializationCard from "./SpecializationCard";
import { ITrackSpecDetails } from "../../data/trackDetails";
import { specStatuses } from "../../data/specializationDetails";
import "react-multi-carousel/lib/styles.css";
import "./Track.css";
import { HiChevronDown } from "react-icons/hi2";
import MicrosoftAzure from "../../images/MicrosoftAzure.svg";
import FrontEnd from "../../images/FrontEnd.svg";
import PowerPlatform from "../../images/PowerPlatform.svg";
import Process from "../../images/Process.svg";
import Domain from "../../images/Domain.svg";
import MachineLearning from "../../images/MachineLearning.svg";
import PowerBI from "../../images/PowerBI.svg";
import Default from "../../images/Default.svg";

function TrackImageMapping(trackName: string) {
	if (trackName === "Azure + BI") return MicrosoftAzure;
	else if (trackName === "Frontend") return FrontEnd;
	else if (trackName === "Power Platform") return PowerPlatform;
	else if (trackName === "Process") return Process;
	else if (trackName === "Domain") return Domain;
	else if (trackName === "Machine Learning") return MachineLearning;
	else if (trackName === "Power BI") return PowerBI;
	else return Default;
}
export const responsive = {
	largeDesktop: {
		breakpoint: { max: Infinity, min: 1600 },
		items: 4,
		slidesToSlide: 4,
	},
	desktop: { breakpoint: { max: 1600, min: 1200 }, items: 3, slidesToSlide: 3 },
	tablet: { breakpoint: { max: 1200, min: 800 }, items: 2, slidesToSlide: 2 },
	mobile: { breakpoint: { max: 800, min: 0 }, items: 1, slidesToSlide: 1 },
};
export default function Track(props: {
	trackName: string;
	specializations: ITrackSpecDetails[];
	layout: "grid" | "carousel";
	totalSpecializations: number;
	updateFavourite: (SpecializationID: number) => void;
}) {
	return props.specializations.length > 0 ? (
		<Accordion sx={{ boxSizing: "border-box", width: "100%" }}>
			<AccordionSummary expandIcon={<HiChevronDown />}>
				<img src={TrackImageMapping(props.trackName)} height={80} width={80} />
				<Typography alignSelf={"center"} variant={"h5"} ml={{ sm: 2, xs: 1 }}>
					{props.trackName}
				</Typography>
				<Box display={"flex"} minWidth={"160px"} ml={"auto"}>
					<List
						disablePadding
						sx={{
							alignSelf: "center",
							whiteSpace: "nowrap",
							"& div": { m: 0, ml: 1 },
						}}
					>
						{specStatuses.map((specStatus, index) => {
							const specCount = props.specializations.filter((spec) => spec.SpecializationStatus === specStatus).length;
							if (specCount !== 0)
								return (
									<ListItem disablePadding key={"status-" + index}>
										<ListItemText>{specStatus + ":"}</ListItemText>
										<ListItemText>{specCount + "/" + props.totalSpecializations}</ListItemText>
									</ListItem>
								);
						})}
					</List>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				{props.layout === "carousel" ? (
					<Carousel responsive={responsive}>
						{props.specializations.map((spec, index) => (
							<SpecializationCard specializationDetails={spec} updateFavourite={props.updateFavourite} key={"specialization-" + index} />
						))}
					</Carousel>
				) : (
					<Box display={"grid"} rowGap={2} gridTemplateColumns={"repeat(auto-fill, minmax(392px, 1fr))"} justifyContent={"space-between"}>
						{props.specializations.map((spec, index) => (
							<SpecializationCard specializationDetails={spec} updateFavourite={props.updateFavourite} key={"specialization-" + index} />
						))}
					</Box>
				)}
			</AccordionDetails>
		</Accordion>
	) : (
		<></>
	);
}
