import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/system";
import { Button, Divider, IconButton, List, Paper, Typography, useTheme } from "@mui/material";
import { UserContext } from "../../App";
import { ITrackSpecDetails } from "../../data/trackDetails";
import { updateSpecializationFavourites } from "../../data/specializationDetails";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";

export default function SpecializationCard(props: { specializationDetails: ITrackSpecDetails; updateFavourite: (SpecializationID: number) => void }) {
	const user = useContext(UserContext).userDetails;
	const theme = useTheme();
	return (
		<Paper
			elevation={3}
			sx={{
				display: "flex",
				flexDirection: "column",
				minHeight: "calc(100% - 16px)",
				width: "400px",
				maxWidth: "90%",
				margin: "8px",
				p: 1,
				boxSizing: "border-box",
			}}
		>
			<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} height={"3em"}>
				<Typography
					fontWeight={600}
					sx={{
						whiteSpace: "initial",
						overflow: "hidden",
						textOverflow: "ellipsis",
						display: "-webkit-box",
						WebkitLineClamp: 2,
						WebkitBoxOrient: "vertical",
					}}
				>
					{props.specializationDetails.Specialization}
				</Typography>
				<IconButton
					onClick={() => {
						updateSpecializationFavourites(user.LdpUserID, props.specializationDetails.SpecializationID).then(() => {
							props.updateFavourite(props.specializationDetails.SpecializationID);
						});
					}}
					sx={{ height: "40px", width: "40px" }}
				>
					{props.specializationDetails.IsFavourite ? (
						<AiFillStar color={theme.palette.text.primary} />
					) : (
						<AiOutlineStar color={theme.palette.text.primary} />
					)}
				</IconButton>
			</Box>
			<Divider />
			<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} flexGrow={1} p={1}>
				<List disablePadding sx={{ whiteSpace: "nowrap" }}>
					<Typography variant={"subtitle2"}> {"Credits: " + props.specializationDetails.Credit}</Typography>
					<Typography variant={"subtitle2"}> {"Specialization Status: " + props.specializationDetails.SpecializationStatus}</Typography>
					{props.specializationDetails.TotalModule ? (
						<Typography variant={"subtitle2"}>
							{"Completed Modules: " + props.specializationDetails.CompletedModule + "/" + props.specializationDetails.TotalModule}
						</Typography>
					) : undefined}
					{props.specializationDetails.TotalQuiz ? (
						<Typography variant={"subtitle2"}>
							{"Completed Quizzes: " + props.specializationDetails.CompletedQuiz + "/" + props.specializationDetails.TotalQuiz}
						</Typography>
					) : undefined}
				</List>
				<Button component={RouterLink} to={"specialization/" + props.specializationDetails.SpecializationID} sx={{ alignSelf: "flex-end" }}>
					View
				</Button>
			</Box>
		</Paper>
	);
}
