import { axiosInstance } from "./axiosInstance";

export interface ITrackDetails {
	TrackID: number;
	Track: string;
}

export interface ITrackSpecDetails {
	TrackID: number;
	Track: string;
	SpecializationID: number;
	Specialization: string;
	IsFavourite: boolean;
	Credit: number;
	SpecializationStatus: string;
	IsSpecializationCompleted: number;
	TotalQuiz: number;
	CompletedQuiz: number;
	TotalModule: number;
	CompletedModule: number;
}

export const fetchTrackSpecDetails = async (userId: number) => {
	let trackSpecDetails: ITrackSpecDetails[] = [];
	await axiosInstance
		.post("/tracks/GetTracksAndSpecializations", userId, {
			headers: { "Content-Type": "application/json" },
		})
		.then((response) => {
			trackSpecDetails = response.data.Data;
		})
		.catch((error) => {
			console.log(error);
		});
	return trackSpecDetails;
};
