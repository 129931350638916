import { useState, useEffect } from "react";
import { Box, Menu, MenuItem, IconButton, Dialog, DialogContent, Typography, Divider, Tooltip } from "@mui/material";
import moment from "moment";
import { NotificationsNoneOutlined, CloseRounded, Visibility } from "@mui/icons-material";
import { INotificationDetails, fetchNotificationDetails } from "../../data/notificationDetails";

export default function NotificationMenu() {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [notificationDetails, setNotificationDetails] = useState<INotificationDetails[]>([]);
	const [hasNewNotifications, setHasNewNotifications] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [selectedNotification, setSelectedNotification] = useState<null | INotificationDetails>(null);

	useEffect(() => {
		fetchNotificationDetails().then((resp: any[]) => {
			setNotificationDetails(resp);
		});
	}, []);

	useEffect(() => {
		const lastNotificationID = localStorage.getItem("lastNotificationID");
		setHasNewNotifications(() => {
			if (notificationDetails.length === 0) return false;
			else {
				if (lastNotificationID === null) return true;
				else return notificationDetails.sort((a, b) => b.ID - a.ID)[0].ID > Number(lastNotificationID);
			}
		});
	}, [notificationDetails]);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setHasNewNotifications(false);
		if (notificationDetails.length > 0) localStorage.setItem("lastNotificationID", notificationDetails.sort((a, b) => b.ID - a.ID)[0].ID.toString());
	};

	const handleMenuItemClick = (item: INotificationDetails) => {
		setAnchorEl(null);
		setSelectedNotification(item);
		setIsDialogOpen(true);
	};

	const handleDialogClose = () => {
		setIsDialogOpen(false);
		setSelectedNotification(null);
	};

	const MenuItems = () =>
		notificationDetails.length === 0 ? (
			<MenuItem disabled>
				<Typography variant="body1">No New Notifications</Typography>
			</MenuItem>
		) : (
			<>
				{notificationDetails
					.sort((a, b) => b.ID - a.ID)
					.map((item, index) => (
						<>
							{index > 0 && <Divider />}
							<MenuItem
								key={index}
								sx={{ whiteSpace: "normal" }}
								onClick={() => {
									handleMenuItemClick(item);
								}}
							>
								<Box width={"100%"}>
									<Box sx={{ fontWeight: "bold", overflow: "hidden", textOverflow: "ellipsis" }}>{item.Title}</Box>
									<Box
										sx={{
											color: "text.secondary",
											display: "-webkit-box",
											fontSize: "14px",
											lineHeight: "1.2rem",
											maxHeight: "2.4rem",
											overflow: "hidden",
											textOverflow: "ellipsis",
											WebkitBoxOrient: "vertical",
											WebkitLineClamp: 2,
										}}
									>
										{item.Description}
									</Box>
									<Box sx={{ display: "flex", justifyContent: "space-between" }}>
										<Tooltip title="View More">
											<Visibility sx={{ color: "text.secondary", fontSize: "smaller", margin: "4px" }} />
										</Tooltip>
										<Box sx={{ color: "text.secondary", fontSize: "smaller" }}>{moment.utc(item.NotificationDate).fromNow()}</Box>
									</Box>
								</Box>
							</MenuItem>
						</>
					))}
			</>
		);

	return (
		<Box sx={{ marginLeft: "auto", marginRight: "16px" }}>
			<IconButton onClick={handleClick} color={"inherit"}>
				<NotificationsNoneOutlined />
				{hasNewNotifications && (
					<Box
						sx={{
							position: "absolute",
							top: "10px",
							right: "10px",
							width: "7px",
							height: "7px",
							backgroundColor: "red",
							borderRadius: "50%",
							display: "block",
						}}
					></Box>
				)}
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{}}
				PaperProps={{
					elevation: 5,
					sx: {
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 1.5,
						width: "100%",
						maxWidth: "30%",
						maxHeight: "60%",
						overflowY: "auto",
						"@media (max-width: 1000px)": {
							mt: 1,
							maxWidth: "50%",
						},
						"@media (max-width: 500px)": {
							maxWidth: "80%",
						},
					},
				}}
			>
				<MenuItems />
			</Menu>
			<Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth={true}>
				<DialogContent>
					{selectedNotification !== null && (
						<Box>
							<Typography variant="h6" component="div" sx={{ fontWeight: "bold", fontSize: "1.2rem", marginBottom: "8px" }}>
								{selectedNotification["Title"]}
							</Typography>
							<IconButton
								aria-label="close"
								onClick={handleDialogClose}
								sx={{
									position: "absolute",
									right: 8,
									top: 8,
									color: (theme) => theme.palette.grey[500],
								}}
							>
								<CloseRounded />
							</IconButton>
							<Typography
								variant="body1"
								sx={{
									whiteSpace: "pre-line",
									fontSize: "1rem",
									lineHeight: "1.5",
								}}
							>
								{selectedNotification["Description"]}
							</Typography>
							<Typography variant="caption" sx={{ color: "gray" }}>
								{moment.utc(selectedNotification["NotificationDate"]).local().format("MMMM DD, YYYY hh:mm A")}
							</Typography>
						</Box>
					)}
				</DialogContent>
			</Dialog>
		</Box>
	);
}
