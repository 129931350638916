import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { HiChevronDown } from "react-icons/hi2";
import { FcIdea } from "react-icons/fc";
import Carousel from "react-multi-carousel";
import { INewsDetails } from "../../data/homeDetails";
import { responsive } from "../tracks/Track";
import NewsFeedCard from "./NewsFeedCard";
export default function NewsFeed(props: { news: INewsDetails[] }) {
	return (
		<Accordion sx={{ boxSizing: "border-box", width: "100%" }} defaultExpanded={true}>
			<AccordionSummary expandIcon={<HiChevronDown size={30} />}>
				<FcIdea size={30} />
				<Typography variant={"h5"} ml={{ sm: 2, xs: 1 }}>
					Learnings
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Carousel responsive={responsive}>
					{props.news.map((item, index) => (
						<NewsFeedCard newsDetails={item} key={"news-" + index} />
					))}
				</Carousel>
			</AccordionDetails>
		</Accordion>
	);
}
