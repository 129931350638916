import { useContext, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Alert, Avatar, Button, Drawer, Divider, IconButton, Input, Link, Stack, Typography } from "@mui/material";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { UserContext, DrawerContext } from "../../App";
import { addMsLearnLink, deleteMsLearnLink } from "../../data/userDetails";
import {
	AiOutlineHome,
	AiFillHome,
	AiOutlineAppstore,
	AiFillAppstore,
	AiOutlineStar,
	AiFillStar,
	AiFillIdcard,
	AiOutlineIdcard,
	AiFillCloseSquare,
} from "react-icons/ai";
import { HiOutlineChevronDoubleLeft, HiOutlineTicket, HiTicket } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import { RiExternalLinkLine } from "react-icons/ri";

export default function SideDrawer() {
	const user = useContext(UserContext).userDetails;
	const setUser = useContext(UserContext).setUserDetails;
	const drawer = useContext(DrawerContext);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [msLearnUsername, setMsLearnUsername] = useState("");
	const [msLearnUpdateFailed, setMsLearnUpdateFailed] = useState(false);
	const startingPage = useLocation().pathname.split("/")[1];
	const pages = [
		{
			name: "My Profile",
			path: "my-profile",
			outlinedIcon: AiOutlineIdcard,
			filledIcon: AiFillIdcard,
		},
		{
			name: "Home",
			path: "",
			outlinedIcon: AiOutlineHome,
			filledIcon: AiFillHome,
		},
		{
			name: "Tracks",
			path: "tracks",
			outlinedIcon: AiOutlineAppstore,
			filledIcon: AiFillAppstore,
		},
		{
			name: "Favorites",
			path: "favorites",
			outlinedIcon: AiOutlineStar,
			filledIcon: AiFillStar,
		},
		//{
		//	name: "Log A Ticket",
		//	path: "log-a-ticket",
		//	outlinedIcon: HiOutlineTicket,
		//	filledIcon: HiTicket,
		//},
	];

	const drawerWidth = 240;
	const theme = useTheme();

	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (event.type === "keydown" && (event as React.KeyboardEvent).key === "Tab") return;
		drawer.setIsDrawerOpen(open);
	};

	const closeDialog = () => {
		setIsDialogOpen(false);
		setMsLearnUpdateFailed(false);
	};

	const updateMsLearn = () => {
		if (
			user.MsLink.filter((link) => {
				return link === msLearnUsername;
			}).length === 0
		) {
			let updatedLinks = [...user.MsLink, msLearnUsername];
			addMsLearnLink({ LdpUserID: user.LdpUserID, msLink: msLearnUsername })
				.then(() => {
					setMsLearnUpdateFailed(false);
					setMsLearnUsername("");
					setUser({ ...user, MsLink: updatedLinks });
				})
				.catch(() => {
					setMsLearnUpdateFailed(true);
				});
		}
	};
	const deleteMSLearnLink = (msLearnUsername: string) => {
		deleteMsLearnLink({ LdpUserID: user.LdpUserID, msLink: msLearnUsername })
			.then(() => {
				let updatedLinks = user.MsLink.filter((link) => {
					return link !== msLearnUsername;
				});
				setUser({ ...user, MsLink: updatedLinks });
			})
			.catch(() => {
				setMsLearnUpdateFailed(true);
			});
	};

	const DrawerHeader = styled("div")(({ theme }) => ({
		...theme.mixins.toolbar,
		alignItems: "center",
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
	}));

	return (
		<>
			<Drawer
				open={drawer.isDrawerOpen}
				onClick={toggleDrawer(false)}
				onKeyDown={toggleDrawer(false)}
				sx={{
					width: drawerWidth,
					"& .MuiDrawer-paper": { width: drawerWidth },
				}}
			>
				<DrawerHeader>
					<IconButton onClick={() => drawer.setIsDrawerOpen(false)}>
						<HiOutlineChevronDoubleLeft />
					</IconButton>
				</DrawerHeader>
				<Divider />
				<Stack spacing={1} alignItems={"center"} padding={1}>
					<Link to={"my-profile"} component={RouterLink as any}>
						<Avatar
							src={user.ProfileURL}
							alt={user.DisplayName}
							sx={{
								bgcolor: theme.palette.primary.main,
								border: "4px solid #d1e6fa",
								fontSize: 48,
								height: 80,
								width: 80,
							}}
						/>
					</Link>

					<Stack spacing={0} alignItems={"center"}>
						<Typography variant={"h6"} maxWidth={"100%"} textAlign={"center"}>
							{user.DisplayName}
						</Typography>
						<Typography variant={"caption"} maxWidth={"100%"} textAlign={"center"}>
							{user.Designation}
						</Typography>
					</Stack>
					<Button id={"ms-learn-details"} variant={"contained"} sx={{ textTransform: "none" }} onClick={() => setIsDialogOpen(true)}>
						MS Learn Details
					</Button>
				</Stack>
				<Divider />
				<List>
					{pages.map((page, index) => (
						<Link key={index} underline={"hover"} color={theme.palette.text.primary} to={page.path} title={page.name} component={RouterLink as any}>
							<ListItem disablePadding sx={{ fontSize: 24 }}>
								<ListItemButton selected={startingPage === page.path} sx={{ pl: 3 }}>
									<ListItemIcon sx={{ minWidth: 48 }}>
										{startingPage === page.path ? (
											<page.filledIcon color={theme.palette.text.primary} />
										) : (
											<page.outlinedIcon color={theme.palette.text.primary} />
										)}
									</ListItemIcon>
									<ListItemText> {page.name} </ListItemText>
								</ListItemButton>
							</ListItem>
						</Link>
					))}
				</List>
			</Drawer>
			<Dialog open={isDialogOpen} onClose={closeDialog} aria-labelledby={"ms-link-dialog-title"} maxWidth={false}>
				<DialogTitle id={"ms-link-dialog-title"}>
					<Typography variant={"h5"}>MS Learn Details</Typography>
					<IconButton
						onClick={closeDialog}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
						}}
					>
						<IoClose />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					{user.MsLink.length > 0 ? (
						<>
							<Typography variant={"h6"}>List of associated MS Learn usernames</Typography>
							<Divider />
							<List
								disablePadding
								sx={{
									maxHeight: 200,
									overflow: "auto",
								}}
							>
								{user.MsLink.map((link, index) => {
									return (
										<ListItem
											key={`link-${index}`}
											secondaryAction={
												<IconButton edge={"end"} aria-label={"delete"}>
													<AiFillCloseSquare
														size={20}
														onClick={() => {
															deleteMSLearnLink(link);
														}}
													/>
												</IconButton>
											}
										>
											<Typography variant={"subtitle2"}>https://docs.microsoft.com/en-us/users/{link}</Typography>
										</ListItem>
									);
								})}
							</List>
						</>
					) : (
						<Alert severity="warning">You do not have any MS Learn username linked to your account.</Alert>
					)}
					<Typography variant={"h6"} mt={3}>
						Steps to retrieve your MS Learn username
					</Typography>
					<Divider />
					<List dense={true} disablePadding sx={{ fontSize: "14px" }}>
						<ListItem>
							1. Sign in to Microsoft Learn
							<Link href={"https://learn.microsoft.com/en-us/training/"} target={"_blank"} display={"flex"} ml={1}>
								<RiExternalLinkLine />
							</Link>
						</ListItem>
						<ListItem>2. Click on the Profile icon and choose 'Profile' from the dropdown menu</ListItem>
						<ListItem>3. The page URL will be as follows: "https://learn.microsoft.com/en-us/users/&lt;username&gt;/"</ListItem>
						<ListItem>4. Copy the username from the page URL and paste below</ListItem>
					</List>
					<Typography variant={"subtitle2"} mt={2}>
						Add MS Learn account: https://learn.microsoft.com/en-us/users/
						<Input
							placeholder={"username"}
							autoFocus
							value={msLearnUsername}
							onChange={(event) => setMsLearnUsername(event.target.value)}
							sx={{ width: "160px" }}
						/>
					</Typography>
					{msLearnUpdateFailed && (
						<Typography variant={"subtitle2"} color={theme.palette.error.main}>
							Failed to update MS Learn Account. Please retry.
						</Typography>
					)}
				</DialogContent>
				<DialogActions>
					<Button variant={"outlined"} sx={{ margin: "auto" }} onClick={updateMsLearn} disabled={msLearnUsername.length ? false : true}>
						Insert
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
