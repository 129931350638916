import { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { AppBar, Breadcrumbs, IconButton, Link, Toolbar, Typography, useTheme } from "@mui/material";
import { ColorModeContext, DrawerContext } from "../../App";
import { fetchSpecCourseNames } from "../../data/breadcrumbValues";
import { FiMenu } from "react-icons/fi";
import { MdOutlineLightMode, MdOutlineDarkMode } from "react-icons/md";
import Logo from "../../images/Logo.svg";
import NotificationMenu from "./NotificationMenu";

export default function Header() {
	const abortController = new AbortController();
	const setIsDrawerOpen = useContext(DrawerContext).setIsDrawerOpen;
	const colorMode = useContext(ColorModeContext);
	const theme = useTheme();
	const [breadcrumbs, setBreadcrumbs] = useState([{ label: "LDP", link: "/" }]);
	const [breadcrumbsDetails, setBreadcrumbsDetails] = useState<{
		specializationId?: number;
		specializationName?: string;
		courseId?: number;
		courseName?: string;
	}>({});
	const path = useLocation().pathname;
	const navigate = useNavigate();

	//Due to React 18, useEffect is called twice, so the breadcrumbs are will not appear for specializations and courses on refreshing the page when in Dev mode
	useEffect(() => {
		let details: {
			specializationId?: number;
			specializationName?: string;
			courseId?: number;
			courseName?: string;
		} = {};
		const startingPage = path.split("/")[1];
		if ((startingPage === "tracks" || startingPage === "favorites") && path.split("/")[2] === "specialization") {
			const specializationId = Number(path.split("/")[3]);
			if (isNaN(specializationId)) {
				navigate("/" + startingPage);
				return;
			}
			details.specializationId = specializationId;

			if (path.split("/")[4] === "course") {
				const courseId = Number(path.split("/")[5]);
				if (isNaN(courseId)) {
					navigate("/" + startingPage);
					return;
				}
				details.courseId = courseId;
			}
			fetchSpecCourseNames(abortController, details.specializationId, details.courseId).then((resp) => {
				if (resp.length === 0) {
					navigate("/" + startingPage);
					return;
				} else if (resp[0].SpecializationName !== "CanceledError") {
					details.specializationName = resp[0].SpecializationName;
					details.courseName = resp[0].CourseName;
					setBreadcrumbsDetails(details);
				}
			});
		} else {
			setBreadcrumbsDetails(details);
		}
		return () => {
			abortController.abort();
		};
	}, [path]);

	useEffect(() => {
		let breadcrumbsList: { label: string; link: string }[] = [{ label: "", link: "" }];
		let breadcrumbPath = "";
		const startingPage = path.split("/")[1];
		if (startingPage !== "") {
			if (startingPage === "my-profile") {
				breadcrumbPath += "/my-profile";
				breadcrumbsList.push({ label: "My Profile", link: breadcrumbPath });
			} else if (startingPage === "tracks") {
				breadcrumbPath += "/tracks";
				breadcrumbsList.push({ label: "Tracks", link: breadcrumbPath });
			} else if (startingPage === "favorites") {
				breadcrumbPath += "/favorites";
				breadcrumbsList.push({ label: "Favorites", link: breadcrumbPath });
			//} else if (startingPage === "log-a-ticket") {
			//	breadcrumbPath += "/log-a-ticket";
			//	breadcrumbsList.push({ label: "Log A Ticket", link: breadcrumbPath });
			}

			if (breadcrumbsDetails.specializationId && breadcrumbsDetails.specializationName) {
				breadcrumbPath += "/specialization/" + breadcrumbsDetails.specializationId;
				breadcrumbsList.push({ label: breadcrumbsDetails.specializationName, link: breadcrumbPath });

				if (breadcrumbsDetails.courseId && breadcrumbsDetails.courseName) {
					breadcrumbPath += "/course/" + breadcrumbsDetails.courseId;
					breadcrumbsList.push({ label: breadcrumbsDetails.courseName, link: breadcrumbPath });
				}
			}
		}
		setBreadcrumbs(breadcrumbsList);
	}, [path, breadcrumbsDetails]);

	return (
		<AppBar position={"sticky"}>
			<Toolbar>
				<IconButton onClick={() => setIsDrawerOpen(true)} sx={{ mr: "12px" }}>
					<FiMenu color={"white"} />
				</IconButton>
				<Link display={"inline-flex"} to={""} title={"LDP"} component={RouterLink as any}>
					<img src={Logo} alt={"LDP Logo"} height={48} width={48} />
				</Link>
				<Breadcrumbs
					separator={"›"}
					aria-label={"breadcrumb"}
					color={"#ffffff"}
					sx={{ "& ol": { maxHeight: "64px", overflow: "hidden", alignContent: "flex-end" } }}
				>
					{breadcrumbs.map((entry, index) => (
						<Link underline={"hover"} key={index} color={"#ffffff"} to={entry.link} title={entry.label} component={RouterLink as any}>
							<Typography variant={"h6"} component={"div"}>
								{entry.label}
							</Typography>
						</Link>
					))}
				</Breadcrumbs>
				<NotificationMenu />
				<IconButton onClick={colorMode.toggleColorMode} color={"inherit"}>
					{theme.palette.mode === "dark" ? <MdOutlineDarkMode /> : <MdOutlineLightMode />}
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}
