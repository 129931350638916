import { useContext, useEffect, useState } from "react";
import { Skeleton, Stack } from "@mui/material";
import { UserContext } from "../../App";
import {
	ITrackSpecCounts,
	IEnrollmentAttemptMetrics,
	INewsDetails,
	fetchTrackSpecializationDetails,
	fetchEnrollmentAndAttemptMetrics,
	getNewsFeed,
} from "../../data/homeDetails";
import { fetchOnGoingSpecializations, IOngoingSpecDetails } from "../../data/specializationDetails";
import NewsFeed from "./NewsFeed";
import InProgressSpecializations from "./InProgressSpecializations";
import Metrics from "./Metrics";

export default function Home() {
	const user = useContext(UserContext).userDetails;
	const [news, setNews] = useState<INewsDetails[]>([]);
	const [newsLoaded, setNewsLoaded] = useState(false);
	const [trackSpecCounts, setTrackSpecCounts] = useState<ITrackSpecCounts[]>([]);
	const [trackSpecCountsLoaded, setTrackSpecCountsLoaded] = useState(false);
	const [enrollmentAttemptDetails, setEnrollmentAttemptDetails] = useState<IEnrollmentAttemptMetrics[]>([]);
	const [enrollmentAttemptDetailsLoaded, setEnrollmentAttemptDetailsLoaded] = useState(false);
	const [specs, setSpecs] = useState<IOngoingSpecDetails[]>([]);
	const [specsLoaded, setSpecsLoaded] = useState(false);
	useEffect(() => {
		getNewsFeed()
			.then((res) => {
				setNews(res);
			})
			.finally(() => {
				setNewsLoaded(true);
			});
		fetchTrackSpecializationDetails()
			.then((res) => {
				setTrackSpecCounts(res);
			})
			.finally(() => {
				setTrackSpecCountsLoaded(true);
			});
		fetchEnrollmentAndAttemptMetrics()
			.then((res) => {
				setEnrollmentAttemptDetails(res);
			})
			.finally(() => {
				setEnrollmentAttemptDetailsLoaded(true);
			});
		fetchOnGoingSpecializations(user.LdpUserID)
			.then((res) => {
				setSpecs(res);
			})
			.finally(() => {
				setSpecsLoaded(true);
			});
	}, [user.LdpUserID]);
	return (
		<Stack spacing={2} m={2}>
			{newsLoaded && trackSpecCountsLoaded && enrollmentAttemptDetailsLoaded && specsLoaded ? (
				<>
					<NewsFeed news={news} />
					<Metrics trackSpecCounts={trackSpecCounts} enrollmentAttemptDetails={enrollmentAttemptDetails} />
					<InProgressSpecializations specs={specs} setSpecs={setSpecs} />
				</>
			) : (
				<>
					<Skeleton variant={"rounded"} width={"100%"} height={"280px"} />
					<Skeleton variant={"rounded"} width={"100%"} height={"280px"} />
					<Skeleton variant={"rounded"} width={"100%"} height={"280px"} />
				</>
			)}
		</Stack>
	);
}
