import React, { useState } from "react";
import { IVideoPlayerProps } from "./VideoPlayer.types";

const VideoPlayer = (props: IVideoPlayerProps) => {
	const [watchedPercentage, setWatchedPercentage] = useState(props.selectedModule.watchedPercentage);
	const [previousTime, setPreviousTime] = useState(0);
	const [previousApiTime, setPreviousApiTime] = useState(props.questionDetails.Score);
	const [totalWatchedPercentageOfVideo, setTotalWatchedPercentageOfVideo] = useState(props.questionDetails.Score);
	const [totalWatchedTime, setTotalWatchedTime] = useState(0);

	/**
	 * Handles the time update event of the video player
	 * @param event
	 */
	const handleTimeUpdate = (event: React.ChangeEvent<HTMLVideoElement>) => {
		const video = event.target;
		if (!video.paused) {
			if (video.currentTime === 0) {
				video.currentTime = previousTime;
				setWatchedPercentage(0);
			}
			const currentTime = video.currentTime;
			const timeDiff = currentTime - previousTime;
			if (0.0 < timeDiff && timeDiff < 0.4) {
				setTotalWatchedTime(totalWatchedTime + timeDiff);
				const percentage = Math.floor((totalWatchedTime / video.duration) * 100);
				percentage >= watchedPercentage && setWatchedPercentage(percentage);
			}
			setPreviousTime(currentTime);

			if (totalWatchedTime - previousApiTime > 120) {
				setPreviousApiTime(video.currentTime);
				props.solutionFunction(props.questionDetails.QuestionID, watchedPercentage.toString());
				setTotalWatchedPercentageOfVideo(watchedPercentage);
			}
		}
	};

	/**
	 * Handles the loaded data event of the video player
	 * @param event
	 * @description This function is called when the video is loaded. It sets the current time of the video to the previous time
	 * and sets the total watched time to the total watched percentage of the video
	 */
	const handleLoadedData = (event: React.ChangeEvent<HTMLVideoElement>) => {
		const video = event.target;
		video.currentTime = video.duration * (totalWatchedPercentageOfVideo / 100);
		setTotalWatchedTime(video.currentTime);
		setPreviousTime(video.duration * (totalWatchedPercentageOfVideo / 100));
	};

	/**
	 * Handles the blur event of the video player
	 * @param event
	 * @description This function is called when the video is blurred. It pauses the video
	 */
	const handleBlur = (event: React.ChangeEvent<HTMLVideoElement>) => {
		const video = event.target;
		video.pause();
	};

	/**
	 * Handles the ended event of the video player
	 * @param event
	 * @description This function is called when the video ends. It checks if the video is watched completely or not.
	 * If the video is watched completely then it calls the solutionFunction to update the solution of the question
	 */
	const handleEnded = (event: React.ChangeEvent<HTMLVideoElement>) => {
		const video = event.target;
		const percentage = Math.floor((video.currentTime / video.duration) * 100);
		percentage >= watchedPercentage && setWatchedPercentage(percentage);
		if (totalWatchedPercentageOfVideo !== 100) {
			props.solutionFunction(props.questionDetails.QuestionID, percentage.toString());
		}
		setWatchedPercentage(percentage);
		setTotalWatchedPercentageOfVideo(percentage);
	};

	return (
		<>
			<video
				style={{ width: "100%", height: "100%" }}
				src={props.selectedModule.videoLink}
				controls
				controlsList="nodownload foobar"
				onTimeUpdate={handleTimeUpdate}
				onLoadedData={handleLoadedData}
				onEnded={handleEnded}
				onBlur={handleBlur}
			/>
			<p>Watched: {(watchedPercentage || totalWatchedPercentageOfVideo) + 0}%</p>
		</>
	);
};

export default VideoPlayer;
