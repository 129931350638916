import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Divider, IconButton, Paper, Skeleton, Tab, Tabs, Typography, useTheme } from "@mui/material";
import parse from "html-react-parser";
import { UserContext } from "../../App";
import { fetchSpecializationDetails, ICourseDetails, updateSpecializationFavourites } from "../../data/specializationDetails";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import CourseCard from "./CourseCard";

export default function Specialization() {
	const user = useContext(UserContext).userDetails;
	const theme = useTheme();
	const { specializationId } = useParams();
	const [specializationName, setSpecializationName] = useState("");
	const [isFavourite, setIsFavourite] = useState(false);
	const [specializationCredits, setSpecializationCredits] = useState(0);
	const [specializationSummary, setSpecializationSummary] = useState("");
	const [showSummary, setShowSummary] = useState(true);
	const [quizzes, setQuizes] = useState<ICourseDetails[]>([]);
	const [modules, setModules] = useState<ICourseDetails[]>([]);

	useEffect(() => {
		if (!isNaN(Number(specializationId))) {
			fetchSpecializationDetails(user.LdpUserID, Number(specializationId)).then((resp) => {
				setSpecializationName(resp.specName);
				setIsFavourite(resp.specIsFavourite);
				setSpecializationCredits(resp.specCredits);
				setSpecializationSummary(resp.specSummary);
				setQuizes(resp.specCourses.filter((course) => course.IsQuiz));
				setModules(resp.specCourses.filter((course) => !course.IsQuiz));
			});
		}
	}, [specializationId, user.LdpUserID]);

	return (
		<Box p={{ sm: 2, xs: 1 }}>
			{specializationId && specializationName !== "" ? (
				<Paper>
					<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} p={2} pb={1}>
						<Typography variant={"h4"}>{specializationName}</Typography>
						<IconButton
							onClick={() => {
								updateSpecializationFavourites(user.LdpUserID, Number(specializationId)).then(() => {
									setIsFavourite(!isFavourite);
								});
							}}
							sx={{ height: "40px", width: "40px" }}
						>
							{isFavourite ? <AiFillStar color={theme.palette.text.primary} /> : <AiOutlineStar color={theme.palette.text.primary} />}
						</IconButton>
					</Box>
					<Typography variant={"subtitle1"} pl={2}>
						{"Credits: " + specializationCredits}
					</Typography>
					<Divider />
					<Box p={2}>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs
								value={Number(showSummary)}
								onChange={(event, value) => {
									setShowSummary(value);
								}}
							>
								<Tab label={"Introduction"} value={1} />
								<Tab label={"Courses"} value={0} />
							</Tabs>
						</Box>
						{showSummary ? (
							specializationSummary && <Typography sx={{ mt: 2 }}>{parse(specializationSummary)}</Typography>
						) : (
							<Box>
								{modules.length > 0 && (
									<>
										<Typography variant={"h5"} sx={{ textDecoration: "underline", mt: 2 }}>
											Modules
										</Typography>
										<Box display={"flex"} flexWrap={"wrap"} mt={1}>
											{modules.map((course, index) => (
												<CourseCard courseDetails={course} key={"module-" + index} user={user} />
											))}
										</Box>
									</>
								)}
								{modules.length > 0 && quizzes.length > 0 && <Divider />}
								{quizzes.length > 0 && (
									<>
										<Typography variant={"h5"} sx={{ textDecoration: "underline", mt: 2 }}>
											Quizzes
										</Typography>
										<Box display={"flex"} flexWrap={"wrap"} mt={1}>
											{quizzes.map((course, index) => (
												<CourseCard courseDetails={course} key={"quiz-" + index} user={user} />
											))}
										</Box>
									</>
								)}
							</Box>
						)}
					</Box>
				</Paper>
			) : (
				<Skeleton variant={"rounded"} width={"100%"} height={"480px"} />
			)}
		</Box>
	);
}
