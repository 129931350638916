import { axiosInstance } from "./axiosInstance";

export interface IQuestionDetails {
	QuestionID: number;
	QuestionNo: number;
	Title: string;
	Question: string;
	Weightage: number;
	QuestionStatus: string;
	ProblemStatement: string;
	InputType: string;
	InputTypeID: number;
	SubModuleName: string;
	Score: number;
}

export const courseStates = {
	done: "Pass",
	submitted: "Submitted",
	ongoing: "In Progress",
	pending: "Not Started",
	failed: "Fail",
};
export const incompleteQuestionState = "Partial";
export const questionStates = ["Passed", "Failed", "Evaluating", "Unattempted", incompleteQuestionState];

export const fetchCourseDetails = async (userId: number, courseId: number) => {
	let courseDetails = {
		courseName: "",
		courseStatus: "",
		score: "",
		currentPercentage: 0,
		passingPercentage: 0,
		questionCount: 0,
		attempted: 0,
		questionDetails: [] as IQuestionDetails[],
		enrollmentId: 0,
	};
	const body = { userId: userId, courseId: courseId };
	await axiosInstance
		.post("/courses/GetCourseDetails", body, { headers: { "Content-Type": "application/json" } })
		.then((response) => {
			courseDetails.courseName = response.data.courseDetails[0].CourseName ?? "";
			courseDetails.courseStatus = response.data.courseDetails[0].CourseStatus ?? "";
			courseDetails.score = response.data.courseDetails[0].Score ?? "";
			courseDetails.currentPercentage = response.data.courseDetails[0].CurrentPercentage ?? 0;
			courseDetails.passingPercentage = response.data.courseDetails[0].PassingPercentage ?? 0;
			courseDetails.questionCount = response.data.courseDetails[0].QuestionCount ?? 0;
			courseDetails.attempted = response.data.courseDetails[0].Attempted ?? 0;
			courseDetails.enrollmentId = response.data.courseDetails[0].EnrollmentID ?? 0;
			courseDetails.questionDetails = response.data.questions ?? [];
		})
		.catch((error) => {
			console.log(error);
		});
	return courseDetails;
};

export const enrollCourse = async (userEmail: string, courseId: number, reEnroll: boolean) => {
	const body = {
		CourseID: courseId,
		ReEnroll: reEnroll,
		IsUpgrade: false,
		email: userEmail,
	};
	return await axiosInstance.post("/training/GetTrainingData", body, { headers: { "Content-Type": "application/json" }, params: { email: userEmail } });
};
