import React, { createContext, useEffect, useState, useMemo } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Backdrop, CircularProgress, CssBaseline } from "@mui/material";
import { IUserDetails, defaultUserDetails, fetchUserDetails } from "./data/userDetails";
import Header from "./components/header/Header";
import SideDrawer from "./components/side-drawer/SideDrawer";
import Home from "./components/home/Home";
import Tracks from "./components/tracks/Tracks";
import LogATicket from "./components/log-a-ticket/LogATicket";
import Specialization from "./components/specialization/Specialization";
import Course from "./components/course/Course";
import Profile from "./components/profile/Profile";
import { logPageView } from "./AppInsights";
export const ColorModeContext = createContext({ toggleColorMode: () => { } });

export const ScreenBlockContext = createContext<{ blockScreen: boolean; setBlockScreen: (newValue: boolean) => void }>({
	blockScreen: true,
	setBlockScreen: () => undefined,
});

export const UserContext = createContext<{
	userDetails: IUserDetails;
	setUserDetails: React.Dispatch<React.SetStateAction<IUserDetails>>;
}>({
	userDetails: defaultUserDetails,
	setUserDetails: () => undefined,
});

export const DrawerContext = createContext<{
	isDrawerOpen: boolean;
	setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
	isDrawerOpen: false,
	setIsDrawerOpen: () => undefined,
});

function App() {
	const [blockScreen, setBlockScreen] = useState(true);
	const [userDetails, setUserDetails] = useState(defaultUserDetails);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const { accounts } = useMsal();
	const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
	const preferredTheme = localStorage.getItem("preferredTheme");
	const [mode, setMode] = useState<"light" | "dark">(preferredTheme === "light" ? "light" : prefersDarkMode || preferredTheme === "dark" ? "dark" : "light");
	const colorMode = useMemo(
		() => ({
			toggleColorMode: () => {
				setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
			},
		}),
		[]
	);

	const theme = useMemo(
		() =>
			createTheme({
				palette: {
					mode,
				},
			}),
		[mode]
	);

	const location = useLocation();

	useEffect(() => {
		fetchUserDetails(accounts[0].username).then((resp) => {
			setUserDetails(resp);
		});
	}, [accounts]);

	useEffect(() => {
		localStorage.setItem("preferredTheme", mode);
	}, [mode]);

	useEffect(() => {
		if (userDetails.LdpUserID !== 0) setBlockScreen(false);
	}, [userDetails]);

	useEffect(() => {
		logPageView(location.pathname);
	}, [location.pathname]);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<ScreenBlockContext.Provider value={{ blockScreen: blockScreen, setBlockScreen: setBlockScreen }}>
					<Backdrop sx={{ color: "#fff", zIndex: theme.zIndex.drawer + 1 }} open={blockScreen}>
						<CircularProgress color="inherit" />
					</Backdrop>
					{userDetails.LdpUserID !== 0 && (
						<UserContext.Provider value={{ userDetails: userDetails, setUserDetails: setUserDetails }}>
							<DrawerContext.Provider
								value={{
									isDrawerOpen: isDrawerOpen,
									setIsDrawerOpen: setIsDrawerOpen,
								}}
							>
								<Header />
								<SideDrawer />
							</DrawerContext.Provider>
							<Routes>
								<Route path="" element={<Home />} />
								<Route path="my-profile" element={<Profile />} />
								<Route path="tracks">
									<Route path="" element={<Tracks onlyShowFavourites={false} />} />
									<Route path="specialization/:specializationId">
										<Route path="" element={<Specialization />} />
										<Route path="course/:courseId" element={<Course />} />
										<Route path="*" element={<Navigate to="" />} />
									</Route>
									<Route path="*" element={<Navigate to="" />} />
								</Route>
								<Route path="favorites">
									<Route path="" element={<Tracks onlyShowFavourites={true} />} />
									<Route path="specialization/:specializationId">
										<Route path="" element={<Specialization />} />
										<Route path="course/:courseId" element={<Course />} />
										<Route path="*" element={<Navigate to="" />} />
									</Route>
									<Route path="*" element={<Navigate to="" />} />
								</Route>
								{/*<Route path="log-a-ticket" element={<LogATicket />} />*/}
								<Route path="*" element={<Navigate to="" />} />
							</Routes>
						</UserContext.Provider>
					)}
				</ScreenBlockContext.Provider>
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export default App;