import { axiosInstance } from "./axiosInstance";

export interface ITrackSpecCounts {
	Track: string;
	Completed: number;
	"In Progress": number;
	"Not Started": number;
}

export interface IEnrollmentAttemptMetrics {
	Category: string;
	SpecializationID: number;
	CourseID: number;
	CourseName: string;
	Count: number;
}

export interface INewsDetails {
	Url: string;
	Sender: string;
	Subject: string;
	Content: string;
	CreatedDate: string;
}

export const fetchTrackSpecializationDetails = async () => {
	let trackProgressDetails: ITrackSpecCounts[] = [];
	await axiosInstance
		.get("/metrics/GetTrackSpecializationMetrics", {
			headers: { "Content-Type": "application/json" },
		})
		.then((response) => {
			trackProgressDetails = response.data.Data;
		})
		.catch((error) => {
			console.log(error);
		});
	return trackProgressDetails;
};

export const fetchEnrollmentAndAttemptMetrics = async () => {
	let trackProgressDetails: any[] = [];
	await axiosInstance
		.get("/metrics/GetEnrollmentAndAttemptMetrics", {
			headers: { "Content-Type": "application/json" },
		})
		.then((response) => {
			trackProgressDetails = response.data.Data;
		})
		.catch((error) => {
			console.log(error);
		});
	return trackProgressDetails;
};

export const getNewsFeed = async (): Promise<INewsDetails[]> => {
	let newsData: INewsDetails[] = [];
	await axiosInstance
		.get(
			"https://graph.microsoft.com/beta/teams/616fac86-19ac-40a2-85bb-15d517e1076f/channels/19%3af228d631e6df4474a6b774d8d0a062db%40thread.skype/messages?$top=15"
		)
		.then((res) => {
			newsData = res.data.value
				.map((item: any) => {
					let senderName = item.from?.user.displayName ?? "";
					let endingPos = senderName.indexOf("|") >= 0 ? senderName.indexOf("|") : senderName.length;
					senderName = senderName.substring(0, endingPos);
					return {
						Url: item.webUrl,
						Sender: senderName,
						Subject: item.subject && item.subject.length > 0 ? item.subject : `${senderName} has shared a learning`,
						Content: item.body.content,
						CreatedDate: item.createdDateTime,
					};
				})
				.filter((item: any) => {
					return !item.Content.includes("<systemEventMessage/>");
				});
		})
		.catch((error) => {
			console.log(error);
		});
	newsData.sort((a, b) => {
		return a.CreatedDate <= b.CreatedDate ? 1 : -1;
	});
	return newsData;
};
