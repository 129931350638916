import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
export const applicationInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'cebb3962-98b3-43e2-96c9-2ac48abd790b',
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
export const appInsights = applicationInsights.loadAppInsights();

export const logPageView = (name, prop) => {
    appInsights.trackPageView({ name: name, properties: prop });
}
export const logEvent = (name, prop) => {
    appInsights.trackEvent({ name: name, properties: prop });
}
export const logException = (ex) => {
    appInsights.trackException({ exception: ex });
}

