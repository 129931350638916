import { axiosInstance } from "./axiosInstance";

export const fetchSpecCourseNames = async (abortController: AbortController, specializationId: number, courseId?: number) => {
	let specCourseName: { SpecializationName: string; CourseName?: string }[] = [];
	const body = {
		specId: specializationId,
		courseId: courseId,
	};
	await axiosInstance
		.post("/details/GetSpecializationAndCourseNames", body, { headers: { "Content-Type": "application/json" }, signal: abortController.signal })
		.then((response) => {
			specCourseName = response.data.Data;
		})
		.catch((error) => {
			if (error.name === "CanceledError") {
				specCourseName = [{ SpecializationName: "CanceledError" }];
			} else {
				console.log(error);
			}
		});
	return specCourseName;
};
