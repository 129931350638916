import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Alert,
	Box,
	Button,
	Container,
	Divider,
	Fab,
	Paper,
	Skeleton,
	Snackbar,
	Tab,
	Tabs,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	useTheme,
} from "@mui/material";
import { ScreenBlockContext, UserContext } from "../../App";
import Question from "./Question";
import { courseStates, fetchCourseDetails, incompleteQuestionState, IQuestionDetails, questionStates } from "../../data/courseDetails";
import { axiosInstance } from "../../data/axiosInstance";

function getDistinctSubmodules(questionDetails: IQuestionDetails[]) {
	let subModules: string[] = [];
	questionDetails.forEach((question, index, self) => {
		if (index === self.findIndex((q) => q.SubModuleName === question.SubModuleName)) {
			subModules.push(question.SubModuleName);
		}
	});
	return subModules;
}

export default function Course() {
	const setBlockScreen = useContext(ScreenBlockContext).setBlockScreen;
	const user = useContext(UserContext).userDetails;
	const theme = useTheme();
	const navigate = useNavigate();
	const { courseId } = useParams();
	const [courseName, setCourseName] = useState("");
	const [courseStatus, setCourseStatus] = useState("");
	const [score, setScore] = useState("");
	const [enrollmentId, setEnrollmentId] = useState(0);
	const [currentPercentage, setCurrentPercentage] = useState(0.0);
	const [passingPercentage, setPassingPercentage] = useState(0);
	const [attemptedQuestions, setAttemptedQuestions] = useState(0);
	const [questionCount, setQuestionCount] = useState(0);
	const [filters, setFilters] = useState<string[]>([]);
	const [subModules, setSubModules] = useState<string[]>([]);
	const [selectedSubmodule, setSelectedSubmodule] = useState(0);
	const [questionDetails, setQuestionDetails] = useState<IQuestionDetails[]>([]);
	const [solutions, setSolutions] = useState<{ QuestionID: number; Solution: string | File }[]>([]);
	const [videoSolutions, setVideoSolutions] = useState<{ QuestionID: number; Solution: string | File }[]>([]);
	const [showSubmmitedMessage, setShowSubmittedMessage] = useState(false);

	const updateSolution = useCallback(
		(questionId: number, newSolution: string | File) => {
			let prevSolutions = [...solutions];
			const questionIndex = prevSolutions.findIndex((sol) => sol.QuestionID === questionId);
			if (questionIndex === -1 && newSolution !== "") prevSolutions.push({ QuestionID: questionId, Solution: newSolution });
			else {
				if (newSolution === "") prevSolutions.splice(questionIndex, 1);
				else prevSolutions[questionIndex].Solution = newSolution;
			}
			setSolutions(prevSolutions);
		},
		[solutions]
	);

	const updateVideoSolution = useCallback(
		(questionId: number, newSolution: string | File) => {
			let prevSolutions = [...videoSolutions];
			if (newSolution !== "") {
				if (prevSolutions.length === 0) {
					prevSolutions.push({ QuestionID: questionId, Solution: newSolution });
				} else {
					prevSolutions[0].QuestionID = questionId;
					prevSolutions[0].Solution = newSolution;
				}
				setVideoSolutions(prevSolutions);
			}
		},
		[videoSolutions]
	);

	const closeSubmittedMessage = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			setShowSubmittedMessage(false);
		}
		setShowSubmittedMessage(false);
	};

	useEffect(() => {
		if (!isNaN(Number(courseId)) && !isNaN(Number(user.LdpUserID))) {
			fetchCourseDetails(user.LdpUserID, Number(courseId)).then((resp) => {
				resp.questionDetails.forEach((question) => {
					if (question.InputTypeID === 3) {
						const getVideoURL =
							question.Question &&
							question.Question.match(/src="([^"]+)"/g)?.[0]
								.replace(/src="/g, "")
								.replace(/"/g, "");
						question.Question = getVideoURL || "";
					}
				});
				setCourseName(resp.courseName);
				setCourseStatus(resp.courseStatus);
				setScore(resp.score);
				setCurrentPercentage(resp.currentPercentage);
				setPassingPercentage(resp.passingPercentage);
				setAttemptedQuestions(resp.attempted);
				setQuestionCount(resp.questionCount);
				setQuestionDetails(resp.questionDetails);
				setEnrollmentId(resp.enrollmentId);
			});
		}
	}, [user.LdpUserID, courseId]);

	useEffect(() => {
		setSubModules(getDistinctSubmodules(questionDetails));
	}, [questionDetails]);

	useEffect(() => {
		if (videoSolutions.length > 0) {
			submitQuestions(true);
		}
	}, [videoSolutions]);

	const submitQuestions = (submitVideoType: boolean = false) => {
		!submitVideoType && setBlockScreen(true);
		const selectedSolutions = submitVideoType ? videoSolutions : solutions;
		let body = selectedSolutions?.map((ele) => {
			let tempQuestionDetails = questionDetails.filter((ele2) => ele2.QuestionID === ele.QuestionID)[0];
			let temp: any = {
				...ele,
				EnrollmentID: enrollmentId,
				CourseID: Number(courseId),
				QuestionNo: tempQuestionDetails.QuestionNo,
				Answered: true,
				sSubmissionSelector: "answer" + tempQuestionDetails.QuestionNo.toString(),
				oElement: "submit",
				InputTypeID: tempQuestionDetails.InputTypeID,
				CourseName: courseName,
				UploadToBlob: false,
				Submission: typeof ele.Solution === "object" ? null : tempQuestionDetails.InputTypeID === 3 ? "Video" : ele.Solution,
				FileName:
					tempQuestionDetails.InputTypeID === 1 && typeof ele.Solution === "object"
						? ele.Solution?.name
						: tempQuestionDetails.InputTypeID === 3
						? "Video.json"
						: "Answer.json",
				File: typeof ele.Solution === "object" ? ele.Solution : null,
				Solution: typeof ele.Solution === "object" ? null : tempQuestionDetails.InputTypeID === 3 ? "Video" : ele.Solution,
				VideoWatchedPercentage:
					tempQuestionDetails.InputTypeID === 3 ? ele.Solution : Number.isInteger(tempQuestionDetails.Score) ? tempQuestionDetails.Score : 0,
			};
			return temp;
		});
		const fileQuestion = body?.filter((ele) => ele.InputTypeID === 1);
		const nonFileQuestion = body?.filter((ele) => ele.InputTypeID !== 1);
		if (nonFileQuestion?.length)
			axiosInstance
				.post("/training/UpdateProgressInfo", nonFileQuestion, {
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("IdToken")}`,
						"Content-Type": "application/json",
					},
					params: {
						email: user.Email,
					},
				})
				.then(() => {
					setSolutions([]);
					return fetchCourseDetails(user.LdpUserID, Number(courseId));
				})
				.then((resp) => {
					resp.questionDetails.forEach((question) => {
						if (question.InputTypeID === 3) {
							const getVideoURL =
								question.Question &&
								question.Question.match(/src="([^"]+)"/g)?.[0]
									.replace(/src="/g, "")
									.replace(/"/g, "");
							question.Question = getVideoURL || "";
						}
					});
					!submitVideoType && setShowSubmittedMessage(true);
					setCourseName(resp.courseName);
					setCourseStatus(resp.courseStatus);
					setScore(resp.score);
					setCurrentPercentage(resp.currentPercentage);
					setPassingPercentage(resp.passingPercentage);
					setAttemptedQuestions(resp.attempted);
					setQuestionCount(resp.questionCount);
					setQuestionDetails(resp.questionDetails);
					setEnrollmentId(resp.enrollmentId);
					setBlockScreen(false);
				})
				.catch((error) => {
					console.log(error);
				});
		if (fileQuestion?.length) {
			fileQuestion.forEach((temp, index) => {
				let formData = new FormData();
				Object.keys(temp).forEach((ele) => {
					if (`${ele}` === "File") {
						formData.append(`${ele}`, temp[`${ele}`], temp[`${ele}`]?.name);
					} else {
						formData.append(`${ele}`, temp[`${ele}`]);
					}
				});
				axiosInstance
					.post("/training/FileProgressInfo", formData, {
						headers: {
							Authorization: `Bearer ${sessionStorage.getItem("IdToken")}`,
							"Content-Type": "multipart/form-data",
						},
						params: {
							email: user.Email,
						},
					})
					.then(() => {
						setSolutions([]);
						if (fileQuestion?.length === index + 1)
							fetchCourseDetails(user.LdpUserID, Number(courseId))
								.then((resp) => {
									resp.questionDetails.forEach((question) => {
										if (question.InputTypeID === 3) {
											const getVideoURL =
												question.Question &&
												question.Question.match(/src="([^"]+)"/g)?.[0]
													.replace(/src="/g, "")
													.replace(/"/g, "");
											question.Question = getVideoURL || "";
										}
									});
									setShowSubmittedMessage(true);
									setCourseName(resp.courseName);
									setCourseStatus(resp.courseStatus);
									setScore(resp.score);
									setCurrentPercentage(resp.currentPercentage);
									setPassingPercentage(resp.passingPercentage);
									setAttemptedQuestions(resp.attempted);
									setQuestionCount(resp.questionCount);
									setQuestionDetails(resp.questionDetails);
									setEnrollmentId(resp.enrollmentId);
									setBlockScreen(false);
								})
								.catch((error) => {
									console.log(error);
								});
					})
					.catch((error) => {
						console.log(error);
					});
			});
		}
	};
	function endCourse() {
		let body: any = {
			Id: enrollmentId,
			CourseId: Number(courseId),
			UserId: user.LdpUserID,
			EndDate: new Date(),
			Result: "",
		};
		setBlockScreen(true);
		axiosInstance
			.post("/Training/IsCourseComplete", body, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("IdToken")}`,
					"Content-Type": "application/json",
				},
				params: {
					email: user.Email,
				},
			})
			.then(() => {
				setBlockScreen(false);
				navigate("..");
			})
			.catch((error) => {
				console.log(error);
			});
	}
	return (
		<Box p={{ sm: 2, xs: 1 }}>
			{courseId && courseName !== "" ? (
				<Paper>
					<Box p={2}>
						<Box display={"flex"} flexWrap={"wrap"} justifyContent={"space-between"} rowGap={2}>
							<Typography variant={"h4"}>{courseName}</Typography>
							{courseStatus === courseStates.ongoing && (
								<Button variant="contained" color="secondary" onClick={endCourse} disabled={attemptedQuestions !== questionCount}>
									End Course
								</Button>
							)}
						</Box>
						<Box mt={1} ml={2} maxWidth={"600px"} display={"flex"} flexWrap={"wrap"} columnGap={3}>
							{score && (
								<Typography variant={"subtitle2"}>
									{"Score: " +
										score +
										" (" +
										currentPercentage?.toLocaleString("en-us", {
											maximumFractionDigits: 2,
										}) +
										"%)"}
								</Typography>
							)}
							{courseStatus === courseStates.pending && <Typography variant={"subtitle2"}>{"Total Questions: " + questionCount}</Typography>}
							<Typography variant={"subtitle2"}>
								{"Passing Criteria: " +
									passingPercentage?.toLocaleString("en-us", {
										maximumFractionDigits: 2,
									}) +
									"%"}
							</Typography>
							{courseStatus === courseStates.ongoing && (
								<Typography variant={"subtitle2"}>{"Attempted Questions: " + attemptedQuestions + "/" + questionCount}</Typography>
							)}
						</Box>
					</Box>
					<Divider />
					<Box display={"flex"} justifyContent={"flex-end"} position={"sticky"} mt={1} top={64} zIndex={2}>
						<ToggleButtonGroup
							value={filters}
							onChange={(event, value: string[]) => setFilters(value)}
							aria-label={"Question Filters"}
							color={"info"}
							size={"small"}
							sx={{
								mt: 1,
								mr: 2,
								backdropFilter: "blur(12px)" + (theme.palette.mode === "dark" ? " brightness(70%)" : ""),
							}}
						>
							{courseStatus === courseStates.ongoing &&
								questionStates.map((questionState, index) => (
									<ToggleButton
										value={questionState}
										aria-label={questionState}
										key={"filter-" + index}
										title={questionState === incompleteQuestionState ? "Partially Completed Questions" : questionState + " Questions"}
									>
										{questionState}
									</ToggleButton>
								))}
						</ToggleButtonGroup>
					</Box>
					<Box p={2} position={"relative"} display={"flex"}>
						<Box position={"sticky"} borderRight={1} borderColor={"divider"} height={"fit-content"} top={"80px"}>
							<Tabs
								value={selectedSubmodule}
								orientation={"vertical"}
								variant={"scrollable"}
								scrollButtons={"auto"}
								sx={{ maxHeight: "calc(100vh - 200px)" }}
								onChange={(event, value: number) => {
									setSelectedSubmodule(value);
									window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
								}}
							>
								{subModules.map((subModule, index) => (
									<Tab label={subModule} value={index} key={"SubModule-" + index} sx={{ textAlign: "right", alignItems: "end", pl: 0 }} />
								))}
							</Tabs>
						</Box>
						<Container maxWidth={"md"} sx={{ width: "100%" }}>
							{questionDetails.map((question, index) => (
								<Question
									details={question}
									visible={
										question.SubModuleName === subModules[selectedSubmodule] &&
										(filters.length === 0 ||
											filters.includes(question.QuestionStatus) ||
											(filters.includes(incompleteQuestionState) && !questionStates.includes(question.QuestionStatus)))
									}
									showInput={courseStatus === courseStates.ongoing}
									resetInput={solutions.length === 0}
									key={"question-" + index}
									solutionFunction={question.InputTypeID === 3 ? updateVideoSolution : updateSolution}
									videoSolutions={videoSolutions}
								/>
							))}
						</Container>
					</Box>
					{courseStatus === courseStates.ongoing && (
						<Box position={"sticky"} bottom={0} left={"100%"} width={"fit-content"}>
							<Fab
								variant={"extended"}
								color={"info"}
								size={"medium"}
								aria-label={"submit"}
								sx={{ mb: 2, mr: 2 }}
								disabled={solutions.length === 0}
								onClick={() => submitQuestions()}
							>
								{"Submit" + (solutions.length === 0 ? "" : " (" + solutions.length + ")")}
							</Fab>
						</Box>
					)}
					{showSubmmitedMessage && (
						<Snackbar open={showSubmmitedMessage} autoHideDuration={3000} onClose={closeSubmittedMessage}>
							<Alert onClose={closeSubmittedMessage} variant="filled" severity="success" sx={{ width: "100%" }}>
								Submitted successfully!
							</Alert>
						</Snackbar>
					)}
				</Paper>
			) : (
				<>
					<Skeleton variant={"rounded"} width={"100%"} height={"100px"} />
					<Skeleton variant={"rounded"} width={"420px"} height={"40px"} sx={{ ml: "auto", mt: 2, mb: 2 }} />
					<Box display={"flex"}>
						<Skeleton variant={"rounded"} width={"30%"} height={"calc(60vh + 16px)"} sx={{ maxWidth: "360px" }} />
						<Container maxWidth={"md"} sx={{ width: "100%" }}>
							{[1, 2].map(() => (
								<Skeleton variant={"rounded"} width={"100%"} height={"30vh"} sx={{ mb: 2 }} />
							))}
						</Container>
					</Box>
					<Skeleton variant={"rounded"} width={"100px"} height={"40px"} sx={{ ml: "auto", mb: 2 }} />
				</>
			)}
		</Box>
	);
}
