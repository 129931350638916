import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { publicClientApplication } from "../index";

export const acquireToken = async () => {
	let accounts = publicClientApplication.getAllAccounts();
	const accessTokenRequest = {
		scopes: ["user.read", "profile"],
		account: accounts[0],
		forceRefresh: true,
	};
	try {
		let tokenResponse = await publicClientApplication.acquireTokenSilent(accessTokenRequest);
		return {
			idToken: tokenResponse.idToken,
			accessToken: tokenResponse.accessToken,
			expires: tokenResponse.expiresOn?.toString() ?? "",
		};
	} catch (error) {
		if (error instanceof InteractionRequiredAuthError) {
			publicClientApplication.acquireTokenRedirect(accessTokenRequest);
		}
		console.log(error);
	}
};

export const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(async (req) => {
	let idToken = sessionStorage.getItem("IdToken") ?? null;
	let accessToken = sessionStorage.getItem("AccessToken") ?? null;
	let expiresOn = new Date(sessionStorage.getItem("ExpiresOn") ?? "");
	let now = new Date();
	let minDiff = 5 * 60 * 1000; // if token expires within next 5 mins then acquire new token
	let isExpired = expiresOn.getTime() - now.getTime() < minDiff;
	if (isExpired || !idToken || !accessToken) {
		let tokenResponse = await acquireToken();
		if (tokenResponse) {
			sessionStorage.setItem("IdToken", tokenResponse.idToken);
			sessionStorage.setItem("AccessToken", tokenResponse.accessToken);
			sessionStorage.setItem("ExpiresOn", new Date(new Date().valueOf() + 60 * 60 * 1000).toString());
			idToken = tokenResponse.idToken;
			accessToken = tokenResponse.accessToken;
		}
	}
	if (!req.headers) {
		req.headers = {};
	}
	if (req.url?.includes("graph.microsoft.com")) req.headers.Authorization = `Bearer ${accessToken ?? ""}`;
	else req.headers.Authorization = `Bearer ${idToken ?? ""}`;

	return req;
});
