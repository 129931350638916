import { useContext, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { Box } from "@mui/system";
import { Button, Dialog, DialogActions, DialogTitle, Divider, List, Paper, Typography, useTheme } from "@mui/material";
import { ScreenBlockContext } from "../../App";
import { ICourseDetails } from "../../data/specializationDetails";
import { courseStates, enrollCourse } from "../../data/courseDetails";
import { IUserDetails } from "../../data/userDetails";

export default function CourseCard(props: { courseDetails: ICourseDetails; user: IUserDetails }) {
	const theme = useTheme();
	const navigate = useNavigate();
	const setBlockScreen = useContext(ScreenBlockContext).setBlockScreen;
	const [showReEnrollmentDialog, setShowReEnrollmentDialog] = useState(false);
	const statusColor = (() => {
		if (props.courseDetails.Result === courseStates.done) return theme.palette.success.light;
		else if (props.courseDetails.Result === courseStates.submitted) return theme.palette.warning.light;
		else if (props.courseDetails.Result === courseStates.ongoing) return theme.palette.warning.light;
		else if (props.courseDetails.Result === courseStates.pending) return theme.palette.text.primary;
		else if (props.courseDetails.Result === courseStates.failed) return theme.palette.error.light;
		else return theme.palette.text.primary;
	})();
	const enrollmentButtonText = (() => {
		if (props.courseDetails.Result === courseStates.pending) return "Start";
		else return "Re-Enroll";
	})();
	function enrollment(reEnrollment: boolean) {
		setBlockScreen(true);
		enrollCourse(props.user.Email, props.courseDetails.CourseID, reEnrollment).then(() => {
			setBlockScreen(false);
			navigate("course/" + props.courseDetails.CourseID);
		});
	}
	function courseEnrollment() {
		const reEnroll = props.courseDetails.Result === courseStates.done || props.courseDetails.Result === courseStates.failed;
		if (reEnroll) {
			setShowReEnrollmentDialog(true);
		} else {
			enrollment(false);
		}
	}

	return (
		<>
			<Dialog open={showReEnrollmentDialog} onClose={() => setShowReEnrollmentDialog(false)}>
				<DialogTitle id="alert-dialog-title">{"Do you want to re-enroll yourself for " + props.courseDetails.CourseName + " ?"}</DialogTitle>
				<DialogActions>
					<Button
						onClick={() => {
							setShowReEnrollmentDialog(false);
							enrollment(true);
						}}
					>
						Yes
					</Button>
					<Button onClick={() => setShowReEnrollmentDialog(false)} autoFocus>
						No
					</Button>
				</DialogActions>
			</Dialog>
			<Paper
				elevation={3}
				sx={{
					display: "flex",
					flexDirection: "column",
					minWidth: "320px",
					width: "30%",
					maxWidth: "400px",
					mr: 4,
					mb: 2,
					p: 1,
					boxSizing: "border-box",
					"&:last-child": {
						mr: 0,
					},
				}}
			>
				<Box display={"flex"} alignItems={"center"} height={"3em"}>
					<Typography
						fontWeight={600}
						sx={{
							whiteSpace: "initial",
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							WebkitLineClamp: 2,
							WebkitBoxOrient: "vertical",
						}}
					>
						{props.courseDetails.CourseName}
					</Typography>
				</Box>
				<Divider />
				<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} flexGrow={1} p={1}>
					<List disablePadding sx={{ alignSelf: "flex-start", whiteSpace: "nowrap" }}>
						<Typography variant={"subtitle2"} display={"inline-block"}>
							{"Course Status:"}
						</Typography>
						&nbsp;
						<Typography variant={"subtitle2"} display={"inline-block"} color={statusColor}>
							{props.courseDetails.Result}
						</Typography>
						{[courseStates.done, courseStates.submitted, courseStates.ongoing, courseStates.failed].includes(props.courseDetails.Result) && (
							<Typography variant={"subtitle2"}>{"Start Date: " + moment(props.courseDetails.CourseStartDate).format("MMMM D, YYYY")}</Typography>
						)}
						{[courseStates.done, courseStates.submitted, courseStates.failed].includes(props.courseDetails.Result) && (
							<Typography variant={"subtitle2"}>{"End Date: " + moment(props.courseDetails.CourseEndDate).format("MMMM D, YYYY")}</Typography>
						)}
						{props.courseDetails.Score && [courseStates.done, courseStates.ongoing, courseStates.failed].includes(props.courseDetails.Result) && (
							<Typography variant={"subtitle2"}>
								{"Score: " +
									props.courseDetails.Score +
									" (" +
									props.courseDetails.CurrentPercentage?.toLocaleString("en-us", {
										maximumFractionDigits: 2,
									}) +
									"%)"}
							</Typography>
						)}
						{props.courseDetails.Result === courseStates.pending && (
							<Typography variant={"subtitle2"}>
								{"Number of Questions: " +
									props.courseDetails.QuestionCount?.toLocaleString("en-us", {
										maximumFractionDigits: 2,
									})}
							</Typography>
						)}
						{[courseStates.ongoing, courseStates.pending, courseStates.failed].includes(props.courseDetails.Result) && (
							<Typography variant={"subtitle2"}>
								{"Passing Criteria: " +
									props.courseDetails.PassingPercentage?.toLocaleString("en-us", {
										maximumFractionDigits: 2,
									}) +
									"%"}
							</Typography>
						)}
					</List>
					<Box display={"flex"} flexDirection={"column"} alignSelf={"flex-end"}>
						<Button component={RouterLink} to={"course/" + props.courseDetails.CourseID}>
							{props.courseDetails.Result === courseStates.ongoing ? "Open" : "View"}
						</Button>
						{(props.courseDetails.Result === courseStates.pending ||
							props.courseDetails.Result === courseStates.done ||
							props.courseDetails.Result === courseStates.failed) && <Button onClick={courseEnrollment}>{enrollmentButtonText}</Button>}
					</Box>
				</Box>
			</Paper>
		</>
	);
}
