import { axiosInstance } from "./axiosInstance";
export interface INotificationDetails {
	ID: number;
	Title: string;
	Description: string;
	NotificationDate: string;
}

export const fetchNotificationDetails = async () => {
	let NotificationDetails: INotificationDetails[] = [];
	await axiosInstance
		.get("/details/GetNotifications", {
			headers: { "content-type": "application/json" },
		})
		.then((response) => {
			NotificationDetails = response.data.Data;
		})
		.catch((error) => {
			console.log(error);
		});
	return NotificationDetails;
};
