import { useCallback, useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import { Alert, Button, Divider, IconButton, Link, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { UserContext } from "../../App";
import { IQuestionDetails, questionStates, incompleteQuestionState } from "../../data/courseDetails";
import { ImCancelCircle } from "react-icons/im";
import VideoPlayer from "../video-player/VideoPlayer";

export default function Question(props: {
	details: IQuestionDetails;
	visible: boolean;
	showInput: boolean;
	resetInput: boolean;
	solutionFunction: (questionId: number, newSolution: string | File) => void;
	videoSolutions: {
		QuestionID: number;
		Solution: string | File;
	}[];
}) {
	const user = useContext(UserContext).userDetails;
	const [userInput, setUserInput] = useState<string | File | undefined>();
	const [videoPlayerProps] = useState({
		moduleName: props.details.SubModuleName,
		watchedPercentage: 0,
		videoLink: props.details.Question,
	});
	const getInputElement = useCallback(() => {
		if (props.details.InputType === "File Upload")
			return (
				<Box minHeight={40} display={"flex"} alignItems={"center"}>
					<Button variant={"contained"} component={"label"}>
						Upload
						<input
							hidden
							accept={"*"}
							type={"file"}
							onClick={(event) => {
								if (event.target instanceof HTMLInputElement) {
									event.target.value = "";
								}
							}}
							onChange={(event) => {
								if (event.target.files && event.target.files[0] !== undefined) {
									setUserInput(event.target.files[0]);
								}
							}}
						/>
					</Button>
					{userInput instanceof File && (
						<>
							<Typography ml={2}>{userInput.name}</Typography>
							<IconButton onClick={() => setUserInput("")}>
								<ImCancelCircle />
							</IconButton>
						</>
					)}
				</Box>
			);
		else if (props.details.InputType === "MS Learn") {
			return user.MsLink.length > 0 ? (
				<Box minHeight={40} display={"flex"} alignItems={"center"}>
					<Button variant={"contained"} color={"success"} onClick={() => setUserInput(user.MsLink[0])}>
						Mark as Done
					</Button>
					{userInput !== "" && userInput !== undefined && (
						<IconButton sx={{ ml: 2 }} onClick={() => setUserInput("")}>
							<ImCancelCircle />
						</IconButton>
					)}
				</Box>
			) : (
				<Alert variant="filled" severity="warning" sx={{ maxWidth: "480px" }}>
					You do not have any MS Learn username linked to your account. Please add your MS Learn details from the sidebar menu.
				</Alert>
			);
		} else if (["Text Submission", "Video Input", "Quiz Question", "UX Review Input", "Sentence Input", "Word Input"].includes(props.details.InputType))
			return (
				<TextField
					label={"Your Submission"}
					variant={"standard"}
					value={userInput}
					onChange={(event) => setUserInput(event.target.value)}
					sx={{ width: "80%" }}
				/>
			);
		else return <></>;
	}, [user, props.details.InputType, userInput]);

	useEffect(() => {
		if (userInput !== undefined) props.solutionFunction(props.details.QuestionID, userInput);
	}, [userInput]);

	useEffect(() => {
		if (props.resetInput) setUserInput("");
	}, [props.resetInput]);

	return (
		<Box display={props.visible ? "block" : "none"} sx={{ "& img": { maxWidth: "100%" } }}>
			<Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"} columnGap={2}>
				<Typography variant={"h5"}>{"Problem Statement " + props.details.QuestionNo}</Typography>
				<Box display={"flex"} flexWrap={"wrap"} columnGap={2}>
					{props.details.InputType !== "No Input" &&
						(questionStates.includes(props.details.QuestionStatus) ? (
							<>
								<Typography variant={"subtitle2"}>{"Weightage: " + props.details.Weightage}</Typography>
								<Typography variant={"subtitle2"}>{"Status: " + props.details.QuestionStatus}</Typography>
							</>
						) : (
							<>
								<Typography variant={"subtitle2"}>{"Score: " + props.details.QuestionStatus}</Typography>
								<Typography variant={"subtitle2"}>{"Status: " + incompleteQuestionState}</Typography>
							</>
						))}
				</Box>
			</Box>
			{props.details.ProblemStatement && (
				<Link href={props.details.ProblemStatement} target={"_blank"}>
					Problem Statement
				</Link>
			)}
			<Box p={1} borderRadius={1} sx={{ backgroundColor: "white", color: "black", "& *": { maxWidth: "100%", overflowWrap: "break-word" } }}>
				{props.details.InputTypeID === 3 ? (
					<VideoPlayer
						questionDetails={props.details}
						selectedModule={videoPlayerProps}
						solutionFunction={props.solutionFunction}
						videoSolutions={props.videoSolutions}
					/>
				) : (
					props.details.Question && parse(props.details.Question)
				)}
			</Box>
			{props.showInput && getInputElement()}
			<Divider sx={{ mt: 1, mb: 1 }} />
		</Box>
	);
}
