import { axiosInstance } from "./axiosInstance";

export interface ICourseDetails {
	CourseID: number;
	CourseName: string;
	Result: string;
	CourseStartDate: string;
	CourseEndDate: string;
	Score: string;
	CurrentPercentage: number;
	PassingPercentage: number;
	QuestionCount: number;
	IsQuiz: boolean;
}
export interface IOngoingSpecDetails {
	SpecializationID: number;
	Specialization: string;
	TotalQuizzes: number;
	TotalModules: number;
	CompletedQuizzes: number;
	CompletedModules: number;
	StartedOn: string;
	LastUpdatedOn: string;
	IsFavourite: boolean;
	Credit: number;
}
export const specStatuses = ["Completed", "In Progress", "Not Started"];

export const fetchSpecializationDetails = async (userId: number, specializationId: number) => {
	let specDetails = {
		specName: "",
		specSummary: "",
		specIsFavourite: false,
		specCredits: 0,
		specCourses: [] as ICourseDetails[],
	};
	const body = { userId: userId, specId: specializationId };
	await axiosInstance
		.post("/specializations/GetSpecializationDetails", body, { headers: { "Content-Type": "application/json" } })
		.then((response) => {
			specDetails.specName = response.data.specializationDetails[0].SpecializationName ?? "";
			specDetails.specIsFavourite = response.data.specializationDetails[0].IsFavourite ?? "";
			specDetails.specSummary = response.data.specializationDetails[0].SpecializationSummary ?? false;
			specDetails.specCredits = response.data.specializationDetails[0].Credit ?? 0;
			specDetails.specCourses = response.data.courses ?? [];
		})
		.catch((error) => {
			console.log(error);
		});
	return specDetails;
};

export const updateSpecializationFavourites = async (userId: number, specializationId: number) => {
	const body = { userId: userId, specId: specializationId };
	await axiosInstance.post("/specializations/UpdateFavourites", body, { headers: { "Content-Type": "application/json" } }).catch((error) => {
		console.log(error);
	});
};

export const fetchOnGoingSpecializations = async (userId: number) => {
	let onGoingSpecs: IOngoingSpecDetails[] = [];
	await axiosInstance
		.post("/specializations/GetInProgressSpecialization", userId, { headers: { "Content-Type": "application/json" } })
		.then((response) => {
			onGoingSpecs = response.data.Data;
		})
		.catch((error) => {
			console.log(error);
		});
	return onGoingSpecs;
};
