import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

const config = {
	auth: {
		authority: "https://login.microsoftonline.com/e4d98dd2-9199-42e5-ba8b-da3e763ede2e",
		clientId: "88d1db10-af99-4943-8fcb-494b3edaf6c3",
		redirectUri: "/",
		postLogoutRedirectUri: "/",
	},
};

export const publicClientApplication = new PublicClientApplication(config);
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<MsalProvider instance={publicClientApplication}>
				<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
					<App />
				</MsalAuthenticationTemplate>
			</MsalProvider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
